import moment from "moment";
import { EmailReportsFieldName, HeaderTabName } from "helpers/Constants";
import { EmailReportGridColumnName } from "../../../views/Reports/Email/EmailReportsGrid/EmailReportGridConstant";

export const initialGridColumns = [
    {
        id: 1,
        colId: EmailReportsFieldName.CAMPAIGN_NAME,
        headerName: EmailReportGridColumnName.CampaignName,
        field: EmailReportsFieldName.CAMPAIGN_NAME,
        minWidth: 220,
        hide: false,
        hideColConfig: false,
        checkedColConfig: true,
    },
    {
        id: 2,
        colId: EmailReportsFieldName.AUTOMATION_NAME,
        headerName: EmailReportGridColumnName.AutomationName,
        field: EmailReportsFieldName.AUTOMATION_NAME,
        minWidth: 220,
        hide: false,
        hideColConfig: false,
        checkedColConfig: true,
    },
    {
        id: 3,
        colId: EmailReportsFieldName.LAST_SEND_DATE,
        headerName: EmailReportGridColumnName.LastSendDate,
        field: EmailReportsFieldName.LAST_SEND_DATE,
        minWidth: 120,
        hide: false,
        hideColConfig: false,
        checkedColConfig: true,
    },
    {
        id: 4,
        colId: EmailReportsFieldName.PROCESSED,
        headerName: EmailReportGridColumnName.Processed,
        field: EmailReportsFieldName.PROCESSED,
        minWidth: 50,
        hide: false,
        hideColConfig: false,
        checkedColConfig: true,
    },
    {
        id: 5,
        colId: EmailReportsFieldName.DELIVERED,
        headerName: EmailReportGridColumnName.Delivered,
        field: EmailReportsFieldName.DELIVERED,
        minWidth: 50,
        hide: false,
        hideColConfig: false,
        checkedColConfig: true,
    },
    {
        id: 6,
        colId: EmailReportsFieldName.OPENED_PERCENTAGE,
        headerName: EmailReportGridColumnName.Opened,
        field: EmailReportsFieldName.OPENED_PERCENTAGE,
        minWidth: 50,
        hide: false,
        hideColConfig: false,
        checkedColConfig: true,
    },
    {
        id: 7,
        colId: EmailReportsFieldName.LINKS_CLICKED_PERCENTAGE,
        headerName: EmailReportGridColumnName.Clicked,
        field: EmailReportsFieldName.LINKS_CLICKED_PERCENTAGE,
        minWidth: 50,
        hide: false,
        hideColConfig: false,
        checkedColConfig: true,
    },
    {
        id: 8,
        colId: EmailReportsFieldName.BOUNCED_PERCENTAGE,
        headerName: EmailReportGridColumnName.Bounced,
        field: EmailReportsFieldName.BOUNCED_PERCENTAGE,
        minWidth: 50,
        hide: false,
        hideColConfig: false,
        checkedColConfig: true,
    },
    {
        id: 9,
        colId: EmailReportsFieldName.UNSUBSCRIBED_PERCENTAGE,
        headerName: EmailReportGridColumnName.Unsubscribed,
        field: EmailReportsFieldName.UNSUBSCRIBED_PERCENTAGE,
        minWidth: 50,
        hide: false,
        hideColConfig: false,
        checkedColConfig: true,
    }
];

export const initialState = {
    // Lists and Collections
    emailReports: [],
    filteredEmailReports: [],
    emailReportChartConfigDataset: [],
    emailReportChartConfigDatasetClient: [],
    emailReportDetails: [],
    emailReportDetailPercentage: [],
    emailReportDetailsUnsubscribed: [],
    emailReportDetailsDelivered: [],
    emailReportDetailsBounced: [],
    emailReportDetailsInvalid: [],
    emailReportDetailsOpened: [],
    emailReportDetailsLinksClicked: [],
    emailReportDetailsLinks: [],
    emailReportDetailsCandidateLinks: [],
    emailReportDetailsSent: [],
    emailReportDetailsUnsubscribedList: [],
    filterGrid: [],
    sortGrid: [],
    gridColumn: initialGridColumns,

    // Objects
    emailReportsFilterModel: {},
    selectedDetailsType: { typeId: 0, label: null },
    selectedEmailReportDetail: {},
    selectedEmailReportLinkDetail: {},

    // Strings
    filterSearchText: "",
    campaignName: undefined,
    clientCampaignName: undefined,
    emailReportDetailsLastSendDate: moment.utc().format("DD MMMM YYYY"),
    emailReportLinkTitle: "",
    emailReportLinkOriginalUrl: "",
    selectedDetailsToken: null,

    // Numbers
    dataId: undefined,
    dataIdClient: undefined,
    comingFromType: 6,
    currentGridType: 6,
    agGridRowsPage: 0,
    agGridRowsPageClient: 0,
    agGridTotalRow: 0,
    agGridTotalRowClient: 0,
    agGridTotalPage: 0,
    agGridTotalPageClient: 0,
    currentTypeTotal: 0,

    // Other
    selectedReport: undefined,
    selectedReportClient: undefined,
    chartInstance: null,
    defaultTab: HeaderTabName.TAB_CANDIDATE,
}; 