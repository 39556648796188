import { atsStore, lookupStore } from "stores";
import { Constants, Enum } from "helpers";
import { convertBase64toFileObject } from "helpers/FileProcessing";

let inputAtsFields = [
  'candidate_address1',
  'address1',
  'candidate_address2',
  'address2',
  'candidate_city',
  'city',
  'candidate_suburb',
  'suburb',
  'candidate_current_employer',
  'current_employer',
  'candidate_first_name',
  'first_name',
  'candidate_last_name',
  'last_name',
  'candidate_current_job_title',
  'current_job_title',
  'candidate_state',
  'state',
  'candidate_desired_job_title',
  'candidate_zip',
  'zip',
];

let dropDownAtsFields = [
  'candidate_country',
  'country',
  'candidate_current_work_type',
  'current_work_type',
  'candidate_desired_pay_frequency',
  'desired_pay_frequency',
  'candidate_current_pay_frequency',
  'current_pay_frequency',
  'candidate_desired_work_type'
];

let checkboxesAtsFields = [];

export const phoneAtsFields = [
  'candidate_mobile',
  'mobile'
];

export const dateAtsFields = [
  'candidate_availability_date',
  'availability.date'
];

export const fileUploadAtsFields = [
  // 'candidate_photo',
  'candidate_resume'
];

export const numberAtsFields = [
  'candidate_desired_low_salary',
  'desired_low_salary',
  'candidate_desired_high_salary',
  'desired_high_salary',
  'candidate_current_salary',
  'current_salary'
];

export const emailAtsFields = [
  'candidate_email',
  'email'
];

export const scaleAtsFields = [
  'candidate_nps'
];

export const isNameHasAts = (name) => {
  // check if have ats on first data before (-),, example "ats-first_name-textinline"
  if (name?.split('-', 3)[0] === "ats") {
    return true;
  }

  return false;
};

export const getAtsTokenName = (name) => {
  // get the value after first dash (-),, example "ats-first_name-textinline" -> get the "first_name"
  return name?.split('-', 3)[1];
};

export const splitCheckboxesReference = (data) => {
  const checkboxesReference = data.split("\n");
  const referenceData = checkboxesReference[1].split("-");
  const fieldId = referenceData[referenceData.length - 1];
  const customFieldData = tripettoStore.listCustomFields().filter(item => item.fieldId === parseInt(fieldId));

  const atsData = {
    atsField: customFieldData.length > 0 ? customFieldData[0].tokenVariable.replace("-" + fieldId, "") : "",
    atsFieldId: fieldId
  }
  return atsData
};

export const tripettoStore = {
  listCheckboxesFields: () => {
    return convertDataAtsToTripetto(
      atsStore.integratedAtsFields,
      convertCustomFieldsToTripetto(atsStore.customFields, checkboxesAtsFields, Enum.SurveyCustomFieldType.List, true),
      atsStore.customFields
    );
  },

  listDropdownFields: () => {
    return convertDataAtsToTripetto(
      atsStore.integratedAtsFields,
      convertCustomFieldsToTripetto(atsStore.customFields, dropDownAtsFields, Enum.SurveyCustomFieldType.List),
      atsStore.customFields
    );
  },

  listTextFields: () => {
    return convertDataAtsToTripetto(
      atsStore.integratedAtsFields,
      convertCustomFieldsToTripetto(atsStore.customFields, inputAtsFields, Enum.SurveyCustomFieldType.Text),
      atsStore.customFields
    );
  },

  listPhoneFields: () => {
    return convertDataAtsToTripetto(atsStore.integratedAtsFields, phoneAtsFields);
  },

  listDateFields: () => {
    return convertDataAtsToTripetto(atsStore.integratedAtsFields, dateAtsFields);
  },

  listFileFields: () => {
    let list = convertDataAtsToTripetto(atsStore.integratedAtsFields, fileUploadAtsFields);
    if (lookupStore.candidateAttachmentCategories.length) list = lookupStore.candidateAttachmentCategories.map(x => ({ label: x.category, value: x.category }));
    return list;
  },

  listNumberFields: () => {
    return convertDataAtsToTripetto(atsStore.integratedAtsFields, numberAtsFields);
  },

  listEmailFields: () => {
    return convertDataAtsToTripetto(atsStore.integratedAtsFields, emailAtsFields);
  },

  listScaleFields: () => {
    return convertDataAtsToTripetto(atsStore.integratedAtsFields, scaleAtsFields);
  },

  listCountries: () => {
    return (
      lookupStore.countries?.map((item) => ({
        name: item.name,
        value: item.code
      })) || []
    );
  },

  listWorkTypes: () => {
    return (
      lookupStore.currentWorkTypes?.map((item) => ({
        name: item.name,
        value: item.workTypeId.toString()
      })) || []
    );
  },

  listPayRates: () => {
    return (
      Enum.OptionSalaryRate?.map((item) => ({
        name: item.text,
        value: item.value
      })) || []
    );
  },

  listCustomFields: () => {
    return (atsStore.customFields.length > 0 ? atsStore.customFields : lookupStore.customFields)
  }
};

export const convertTripettoData = (data) => {
  let finalData = [];

  data.fields.forEach(element => {
    let tempData = {};
    tempData.value = element.value === 0 ? "0" : element.value ? element.value.toString() : "";
    tempData.type = element.type;

    // example data: ats-first_name-textinline
    // check if have ats on first data before -
    if (isNameHasAts(element.name) && element.type !== Constants.TripettoNodeType.CHECKBOXES) {
      tempData.atsField = getAtsTokenName(element.name);
      tempData.atsValue = tempData.value;
      const splitedName = element.name.split("-");
      tempData.fieldId = splitedName.length > 2 ? splitedName[splitedName.length - 1] : "";
    }

    // check if have fileupload in it,, (default stored in 'reference' key)
    if (element.reference && element.slot === Constants.TripettoNodeType.FILE) {
      // get only base64 raw string without type 'base64'
      tempData.fileData = element.reference.split('base64,')[1];
      tempData.contentType = convertBase64toFileObject(element.reference, element.value).type;
      tempData.category = element.name;
    }

    // set value by reference only for DROPDOWN OPTION
    if (element.reference && element.slot === "option") {
      if (isNameHasAts(element.name)) {
        tempData.atsValue = element.reference;
      }
      tempData.value = element.value;
    }

    // add question here
    tempData.question = element.node.props().name;

    // set checkboxes
    const isIntegratedCheckboxes = element.type === Constants.TripettoNodeType.CHECKBOXES && element.slot === "selection";

    if (isIntegratedCheckboxes) {

      if (element.value) {
        const isNullReference = element.reference.includes("ats");
        const checkboxesAtsFields = isNullReference ? splitCheckboxesReference(element.reference) : undefined;
        let checkboxesValue = [];

        if (element.value !== "") {
          checkboxesValue = element.value.split(",");
        }

        tempData.atsField = isNullReference ? checkboxesAtsFields.atsField : undefined;
        tempData.atsValue = isNullReference ? JSON.stringify(checkboxesValue) : undefined;
        tempData.fieldId = isNullReference ? checkboxesAtsFields.atsFieldId : undefined;
        tempData.question = isNullReference ? element.name : element.node.props().name;
        tempData.value = JSON.stringify(checkboxesValue);
      }

    } else if (element.type === Constants.TripettoNodeType.CHECKBOXES && !element.isMapped) {
      let groupedCheckboxes = data.fields.filter(field => field.node.id === element.node.id);
      let checkboxesValue = groupedCheckboxes.filter(field => field.value).map(field => field.name);
      groupedCheckboxes.forEach(field => field.isMapped = true);
      tempData.value = JSON.stringify(checkboxesValue);
      finalData.push(tempData);
    }

    if (isIntegratedCheckboxes ||
      element.type === Constants.TripettoNodeType.DROPDOWN ||
      element.type === Constants.TripettoNodeType.EMAIL ||
      element.type === Constants.TripettoNodeType.FILE ||
      element.type === Constants.TripettoNodeType.NUMBER ||
      element.type === Constants.TripettoNodeType.PHONE ||
      element.type === Constants.TripettoNodeType.TEXT ||
      element.type === Constants.TripettoNodeType.SCALE) {
      finalData.push(tempData);
    }
  });

  return finalData;
};

export const convertCustomFieldsToTripetto = (customFields, fields, type, isMultiSelect = false) => {
  let dataFinal = [...fields];
  customFields.forEach(item => {
    if (item.type === type && item.isMultiSelect === isMultiSelect) {
      dataFinal.push(item.tokenVariable);
    }
  });
  return dataFinal;
};

export const convertDataAtsToTripetto = (atsListData, filterArray, customFields = []) => {
  // final data will look like this (for tripetto purpose)
  // [{
  //   label: "label test",
  //   value: "value test"
  // }]
  let finalData = [];

  atsListData.forEach(element => {
    if (filterArray.includes(element.tokenVariable)) {
      let object = {};
      object.label = element.tokenVariable === 'city' ? "Suburb" : element.tokenVariable === 'candidate_city' ? "Candidate Suburb" : element.displayName;
      object.value = `ats-${element.tokenVariable}`;
      if (!finalData.some(data => data.value === object.value)) {
        finalData.push(object);
      }
    }
  });
  customFields.length > 0 && customFields.forEach(element => {
    if (filterArray.includes(element.tokenVariable)) {
      let object = {};
      object.label = element.displayName;
      object.value = `ats-${element.tokenVariable}`;
      if (!finalData.some(data => data.value === object.value)) {
        finalData.push(object);
      }
    }
  });
  return finalData;
};
