import { action } from "mobx";
import { Enum } from "helpers";


// Array setters
const createArraySetter = (store, key) => action((items) => {
    // Reset only the specific array property, not affecting other store properties
    if (Array.isArray(items)) {
        store[key] = [...items];
    } else if (items?.items && Array.isArray(items.items)) {
        store[key] = [...items.items];
    } else if (items === null || items === undefined) {
        store[key] = [];
    } else {
        console.warn(`${key} received invalid data:`, items);
        // Keep the existing array if the items are invalid
        if (!Array.isArray(store[key])) {
            store[key] = [];
        }
    }
});

// Simple setters
const createSimpleSetter = (store, key) => action((value) => {
    store[key] = value;
});

// Object setters   
const createObjectSetter = (store, key, defaultValues = {}) => action((data) => {
    store[key] = {};
    if (data && typeof data === "object") {
        store[key] = {
            ...defaultValues,
            ...data
        };
    } else {
        console.warn(`${key} received invalid data:`, data);
        store[key] = { ...defaultValues };
    }
});

export const createMutations = (store) => ({
    // Simple setters
    setFilterGrid: createSimpleSetter(store, 'filterGrid'),
    setSortGrid: createSimpleSetter(store, 'sortGrid'),
    setAgGridRowsPage: createSimpleSetter(store, 'agGridRowsPage'),
    setAgGridRowsPageClient: createSimpleSetter(store, 'agGridRowsPageClient'),
    setAgGridTotalRow: createSimpleSetter(store, 'agGridTotalRow'),
    setAgGridTotalRowClient: createSimpleSetter(store, 'agGridTotalRowClient'),
    setAgGridTotalPage: createSimpleSetter(store, 'agGridTotalPage'),
    setAgGridTotalPageClient: createSimpleSetter(store, 'agGridTotalPageClient'),
    updateGridColumn: createSimpleSetter(store, 'gridColumn'),
    setDataId: createSimpleSetter(store, 'dataId'),
    setDataIdClient: createSimpleSetter(store, 'dataIdClient'),
    setComingFromType: createSimpleSetter(store, 'comingFromType'),
    setCurrentGridType: createSimpleSetter(store, 'currentGridType'),
    setDefaultTab: createSimpleSetter(store, 'defaultTab'),
    setSelectedReport: createSimpleSetter(store, 'selectedReport'),
    setSelectedReportClient: createSimpleSetter(store, 'selectedReportClient'),
    setEmailReportDetailsLastSendDate: createSimpleSetter(store, 'emailReportDetailsLastSendDate'),
    setSelectedDetailsType: createObjectSetter(store, 'selectedDetailsType', {
        typeId: 0,
        label: null
    }),
    setEmailReportLinkOriginalUrl: createSimpleSetter(store, 'emailReportLinkOriginalUrl'),
    setEmailReportLinkTitle: createSimpleSetter(store, 'emailReportLinkTitle'),
    setSelectedDetailsToken: createSimpleSetter(store, 'selectedDetailsToken'),
    setCampaignName: createSimpleSetter(store, 'campaignName'),
    setClientCampaignName: createSimpleSetter(store, 'clientCampaignName'),
    setChartInstance: createSimpleSetter(store, 'chartInstance'),
    setEmailReportSearchFilterString: createSimpleSetter(store, 'filterSearchText'),
    setSelectedEmailReportLinkDetail: createSimpleSetter(store, 'selectedEmailReportLinkDetail'),

    // Array setters
    setEmailReportDetailPercentage: action((percentages) => {
        store.emailReportDetailPercentage = [];
        store.emailReportDetailPercentage = [...percentages];
    }),

    setEmailReports: action((reports) => {
        store.emailReports = reports;
        store.filteredEmailReports = [...reports];
        store.emailReportsFilterModel = {};
    }),

    // Complex array setters using the factory
    setEmailReportDetailsUnsubscribed: createArraySetter(store, 'emailReportDetailsUnsubscribed'),
    setEmailReportDetailsDelivered: createArraySetter(store, 'emailReportDetailsDelivered'),
    setEmailReportDetailsBounced: createArraySetter(store, 'emailReportDetailsBounced'),
    setEmailReportDetailsInvalid: createArraySetter(store, 'emailReportDetailsInvalid'),
    setEmailReportDetailsOpened: createArraySetter(store, 'emailReportDetailsOpened'),
    setEmailReportDetailsLinksClicked: createArraySetter(store, 'emailReportDetailsLinksClicked'),
    setEmailReportDetailsLinks: createArraySetter(store, 'emailReportDetailsLinks'),
    setEmailReportDetailsCandidateLinks: createArraySetter(store, 'emailReportDetailsCandidateLinks'),
    setEmailReportDetailsSent: createArraySetter(store, 'emailReportDetailsSent'),
    setEmailReportDetailsUnsubscribedList: createArraySetter(store, 'emailReportDetailsUnsubscribedList'),
    setEmailReportChartConfigDataset: createArraySetter(store, 'emailReportChartConfigDataset'),
    setEmailReportChartConfigDatasetClient: createArraySetter(store, 'emailReportChartConfigDatasetClient'),

    // Complex object setters
    setSelectedEmailReportDetail: createObjectSetter(store, 'selectedEmailReportDetail', {
        totalSent: 0,
        openedPercentage: 0,
        totalOpened: 0,
        bouncedPercentage: 0,
        totalBounced: 0,
        unsubscribePercentage: 0,
        linkClickedPercentage: 0,
        totalLinkClicked: 0,
        deliveredPercentage: 0,
        totalDelivered: 0,
        invalidPercentage: 0,
        totalInvalid: 0,
        unsubscribedListPercentage: 0,
        totalUnsubscribedList: 0,
        reports: {
            campaignName: "N/A",
            automationName: "N/A",
            lastSendDate: null,
            totalLinks: 0,
            unsubscribed: 0
        }
    }),

    // Add new mutation for calculating current type total that uses store's currentGridType
    calculateCurrentTypeTotal: action(() => {
        let total = 0;
        switch (store.currentGridType) {
            case Enum.EmailReportType.Delivered.typeId:
                total = store.selectedEmailReportDetail?.totalDelivered || 0;
                break;
            case Enum.EmailReportType.Opened.typeId:
                total = store.selectedEmailReportDetail?.totalOpened || 0;
                break;
            case Enum.EmailReportType.LinksClicked.typeId:
                total = store.selectedEmailReportDetail?.totalLinkClicked || 0;
                break;
            case Enum.EmailReportType.Bounced.typeId:
                total = store.selectedEmailReportDetail?.totalBounced || 0;
                break;
            case Enum.EmailReportType.Unsubscribed.typeId:
                total = store.selectedEmailReportDetail?.reports?.unsubscribed || 0;
                break;
            case Enum.EmailReportType.Invalid.typeId:
                total = store.selectedEmailReportDetail?.totalInvalid || 0;
                break;
            case Enum.EmailReportType.UnsubscribedList.typeId:
                total = store.selectedEmailReportDetail?.totalUnsubscribedList || 0;
                break;
            case Enum.EmailReportType.Processed.typeId:
                total = store.selectedEmailReportDetail?.totalSent || 0;
                break;
            case Enum.EmailReportType.Links.typeId:
                total = store.selectedEmailReportDetail?.reports?.totalLinks || 0;
                break;
            default:
                total = 0;
        }
        store.currentTypeTotal = total;
        store.agGridTotalRow = total;
        store.agGridTotalPage = Math.ceil(total / store.agGridRowsPage || 10);
    }),

}); 