import { action } from "mobx";
import Alert from 'react-s-alert';
import { Constants, Enum } from "helpers";
import { FormatDateUtc } from "helpers/FormatDateTable";

export const createEmailReportActions = (store) => ({
    handleGetEmailReports: action((response) => {
        if (response.data.result) {
            store.emailReports = response.data.result.items;
        }
        return response;
    }),

    handleGetEmailReportServerSide: action((response) => {
        if (response && response.status === 200 && response.data && !response.data.isError) {
            store.setAgGridTotalRow(response.data.result.totalCount);
            return response.data;
        } else {
            Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);
            return Promise.reject(Constants.ALERT_SOMETHING_WENT_WRONG);
        }
    }),

    handleGetEmailClientReportServerSide: action((response) => {
        if (response && response.status === 200 && response.data && !response.data.isError) {
            store.setAgGridTotalRowClient(response.data.result.totalCount);
            return response.data;
        } else {
            Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);
            return Promise.reject(Constants.ALERT_SOMETHING_WENT_WRONG);
        }
    }),

    handleGetEmailReportDetails: action((response, contactType) => {
        if (response && response.status === 200 && response.data && !response.data.isError) {
            if (response.data.result && Array.isArray(response.data.result) && response.data.result.length > 0) {
                const reportDetail = response.data.result[0];
                store.setSelectedEmailReportDetail(reportDetail);
                
                const openedPercentage = reportDetail?.openedPercentage ?? 0;
                const linkClickedPercentage = reportDetail?.linkClickedPercentage ?? 0;
                const bouncedPercentage = reportDetail?.bouncedPercentage ?? 0;
                const unsubscribePercentage = reportDetail?.unsubscribePercentage ?? 0;

                store.setEmailReportDetailPercentage([
                    Number(openedPercentage).toFixed(2),
                    Number(linkClickedPercentage).toFixed(2),
                    Number(bouncedPercentage).toFixed(2),
                    Number(unsubscribePercentage).toFixed(2),
                ]);

                if (contactType === Enum.ContactType.Candidate) {
                    store.setEmailReportDetailsLastSendDate(FormatDateUtc(reportDetail?.reports?.lastSendDate));
                } else {
                    store.setEmailReportDetailsLastSendDate(FormatDateUtc(reportDetail?.clientReportEmail?.lastSendDate));
                }
            } else {
                console.warn('No report details found in response');
                Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);
            }
        } else {
            console.error('Invalid response format:', response);
            Alert.error("Oops, Something went wrong! Please try again.");
        }
        return response.data;
    }),

    handleGetEmailReportDetailsLinks: action((response) => {
        if (response?.status === 200 && response?.data?.result) {
            if (response.data.result.items && Array.isArray(response.data.result.items)) {
                store.setEmailReportDetailsLinks(response.data.result.items);
            } else {
                console.warn('No items array found in response:', response.data);
                store.setEmailReportDetailsLinks([]);
            }

            if (response.data.result.totalCount !== undefined) {
                store.setAgGridTotalRow(response.data.result.totalCount);
            }  
            return response.data.result.items;
        }
        console.warn('Invalid response format:', response);
        store.setEmailReportDetailsLinks([]);
        return Promise.reject("Failed to load link reports");
    }),

    handleGetEmailReportLinkPageDetails: action((response) => {
        if (response && response.status === 200 && response.data && !response.data.isError) {
            if (Array.isArray(response.data.result) && response.data.result.length > 0) {
                store.setEmailReportDetailsCandidateLinks(response.data.result);
                Alert.success("Email Report Link has been loaded");
            } else {
                store.setEmailReportDetailsCandidateLinks([]);
                Alert.warning("No link data found");
            }
        } else {
            Alert.error("Oops, Something went wrong! Please try again.");
        }
        return response.data;
    }),

    handleGetEmailReportDetailsBounced: action((response) => {
        if (response && response.status === 200 && response.data && !response.data.isError) {
            store.setEmailReportDetailsBounced(response.data.result);
        } else {
            Alert.error("Oops, Something went wrong! Please try again.");
        }
        return response.data;
    }),

    handleError: action((err) => {
        console.error('API error:', err);
        if (err?.response) {
            console.error('Error details:', {
                status: err.response.status,
                statusText: err.response.statusText,
                data: err.response.data,
                config: err.config
            });
        }
        Alert.error(err.message || Constants.ALERT_SOMETHING_WENT_WRONG);
        return Promise.reject(err);
    })
}); 