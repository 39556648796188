import { observable, action } from "mobx";
import { FilterModelPageName, HeaderTabName } from "helpers/Constants";
import { SurveyGridColumnName, SurveyGridFieldName } from "views/Survey/SurveyGrid/SurveyGridConstant";
import { ApiUrl, ApiService, Enum, Constants } from "helpers";
import { convertTripettoData } from "helpers/TripettoHelper"
import { SurveyStateType, SurveyFormFace } from "helpers/enum";
import Alert from 'react-s-alert';
import { SurveyTemplateGridColumnName, SurveyTemplateGridFieldName } from "views/Survey/SurveyTemplateGrid/SurveyTemplateGridConstant";
import { FormatDate } from "helpers";

const surveyStore = observable(
  {
    surveyId: null,
    isStoryTabDisabled: false,
    isFinaliseTabDisabled: true,
    isSurveyFormDirty: false,
    isNavigatedFromFinalise: false,
    surveyTab: HeaderTabName.TAB_SURVEY_STORY, 
    surveyState: SurveyStateType.Active,
    surveyList: [],
    filteredSurveyList: [],
    surveyTemplateList: [],
    filteredSurveyTemplateList: [],
    surveyTags: [],
    previousSurveyName: "",
    surveyName: "",
    surveyDescription: "",
    surveyData: {},
    initialSurveyData: {},
    tripettoBuilder: undefined,
    isOpenEditConfirmationPopup: false,
    isReloadSurvey: false,
    isRestoreSurvey: false,
    surveyFormFace: SurveyFormFace.Autoscroll,
    surveyStyles: null,
    isLoaded: false,
    tagOptions: [],
    isUseTemplate: false,
    isEditTemplate: false,
    isUpdatedSurveyName: false,
    activeGrid: HeaderTabName.TAB_SURVEY_GRID_TEMPLATE,
    SurveyTableFilters: {},
    SurveyTemplatesTableFilters: {},
    filterSearchText: "",

    gridColumn: Object.values(SurveyGridFieldName).map((item, index) => {
      const headerName = Object.values(SurveyGridColumnName);
      return {
        id: index,
        colId: item,
        field: item,
        headerName: headerName[index],
        width: item === SurveyGridFieldName.SurveyAction ? 400 : item === SurveyGridFieldName.SurveyName || item === SurveyGridFieldName.SurveyDescription ? 350 : 200,
        hide: false,
        sort: item === SurveyGridFieldName.SurveyCreatedDate ? 'asc' : null,
        order: index++
      }
    }),

    gridColumnTemplate: Object.values(SurveyTemplateGridFieldName).map((item, index) => {
      const headerName = Object.values(SurveyTemplateGridColumnName);
      return {
        id: index,
        colId: item,
        field: item,
        headerName: headerName[index],
        width: item === SurveyTemplateGridColumnName.SurveyAction ? 350 : item === SurveyTemplateGridColumnName.SurveyName || item === SurveyTemplateGridFieldName.SurveyDescription ? 300 : 200,
        hide: false,
        sort: item === SurveyTemplateGridColumnName.SurveyCreatedDate ? 'asc' : null,
        order: index++
      }
    }),

    setSurveyFilterModel(data) {
      this.SurveyTableFilters = data;
      sessionStorage.setItem(FilterModelPageName.SurveyPage, JSON.stringify(data));
    },

    setSurveyTemplateFilterModel(data) {
      this.SurveyTemplatesTableFilters = data;
      sessionStorage.setItem(FilterModelPageName.SurveyTemplatePage, JSON.stringify(data));
    },

    setIsUpdatedSurveyName(isUpdatedSurveyName) {
      this.isUpdatedSurveyName = isUpdatedSurveyName;
    },

    setIsUseTemplate(isUseTemplate) {
      this.isUseTemplate = isUseTemplate;
    },

    setIsEditTemplate(isEditTemplate) {
      this.isEditTemplate = isEditTemplate;
    },

    setIsLoaded(isLoaded) {
      this.isLoaded = isLoaded;
    },

    setSurveyId(surveyId) {
      this.surveyId = surveyId
    },

    updateGridColumn(gridColumn) {
      this.gridColumn = gridColumn;
    },

    setIsStoryTabDisabled(isStoryTabDisabled) {
      this.isStoryTabDisabled = isStoryTabDisabled;
    },

    setIsFinaliseTabDisabled(isFinaliseTabDisabled) {
      this.isFinaliseTabDisabled = isFinaliseTabDisabled;
    },

    setIsSurveyFormDirty(isSurveyFormDirty) {
      this.isSurveyFormDirty = isSurveyFormDirty;
    },

    setIsNavigatedFromFinalise(isNavigatedFromFinalise) {
      this.isNavigatedFromFinalise = isNavigatedFromFinalise;
    },

    setSurveyTab(surveyTab) {
      this.surveyTab = surveyTab;
      this.isStoryTabDisabled = surveyTab !== HeaderTabName.TAB_SURVEY_STORY;
      this.isFinaliseTabDisabled = surveyTab !== HeaderTabName.TAB_SURVEY_FINALISE;
    },

    setSurveyList(surveyList) {
      this.surveyList = surveyList;
      this.filteredSurveyList = surveyList;
      this.SurveyTableFilters = {};
    },

    setSurveyTemplateList(surveyTemplateList) {
      this.surveyTemplateList = surveyTemplateList;
      this.filteredSurveyTemplateList = surveyTemplateList;
      this.SurveyTemplatesTableFilters = {};
    },

    setSurveyTags(surveyTags) {
      this.surveyTags = surveyTags;
    },

    setSurveyName(surveyName) {
      this.surveyName = surveyName;
      this.isUpdatedSurveyName = true;
      this.isSurveyFormDirty = true;
    },

    setPreviousSurveyName(previousSurveyName){
      this.previousSurveyName = previousSurveyName;
    },

    setSurveyDescription(surveyDescription) {
      this.surveyDescription = surveyDescription;
      this.isSurveyFormDirty = true;
    },

    setSurveyData(surveyData) {
      this.surveyData = surveyData;
      this.isSurveyFormDirty = true;
    },

    setSurveyStateData(surveyState) {
      this.surveyState = surveyState;
      this.isSurveyFormDirty = true;
    },

    setInitialSurveyData(initialSurveyData) {
      this.initialSurveyData = {};
      this.initialSurveyData = { ...initialSurveyData };
    },

    setTripettoBuilder(tripettoBuilder) {
      this.tripettoBuilder = tripettoBuilder;
    },

    setIsOpenConfirmationPopup(isOpenEditConfirmationPopup) {
      this.isOpenEditConfirmationPopup = isOpenEditConfirmationPopup;
    },

    setIsReloadSurvey(isReloadSurvey) {
      this.isReloadSurvey = isReloadSurvey;
    },

    setSurveyStyles(styles) {
      this.surveyStyles = styles;
      this.isSurveyFormDirty = true;
    },

    setSurveyFormFace(surveyFormFace) {
      this.surveyFormFace = surveyFormFace;
      this.isSurveyFormDirty = true;
    },

    restoreBuilder() {
      if (this.tripettoBuilder) {
        //Restores to the last loaded or saved definition. Returns a reference to the builder instance.
        this.tripettoBuilder.restore();
      }
      this.isRestoreSurvey = true;
      return Promise.resolve();
    },

    setTagOptions(tagOptions) {
      this.tagOptions = [
        ...tagOptions
      ];
      this.isSurveyFormDirty = true;
    },

    setActiveGrid(activeGrid) {
      this.activeGrid = activeGrid;
    },

    setSurveySearchFilterString(searchString) {
      const { FormatDateUtc } = FormatDate;

      function removeStateStringSurveyIsPrefixIfItExists(text) {
        var prefix = "Survey is ";
        if (text.indexOf(prefix) === 0) {
          return text.slice(prefix.length);
        }
        return text;
      }

      function ApplySearchStringIfUpdatedDateExists(item) {
        return !!item?.updatedAt && FormatDateUtc(item?.updatedAt).substring(0, 11).toLowerCase().includes(searchString.toLowerCase()) ? true : false;
      }

      this.filterSearchText = searchString;

      this.filteredSurveyList = this.surveyList.filter(item =>
        ApplySearchStringIfUpdatedDateExists(item) ||
        item.name.toLowerCase().includes(searchString.toLowerCase()) ||
        removeStateStringSurveyIsPrefixIfItExists(item.stateDescription).toLowerCase().startsWith(searchString.toLowerCase()) ||
        item.category?.toLowerCase().includes(searchString.toLowerCase()) ||
        item.updatedBy?.toLowerCase().includes(searchString.toLowerCase())
      );
    },

    setSurveyTemplateSearchFilterString(searchString) {
      const { FormatDateUtc } = FormatDate;

      function ApplySearchStringIfUpdatedDateExists(item) {
        return !!item?.updatedAt && FormatDateUtc(item?.updatedAt).substring(0, 11).toLowerCase().includes(searchString.toLowerCase()) ? true : false;
      }

      this.filterSearchText = searchString;

      this.filteredSurveyTemplateList = this.surveyTemplateList.filter(item =>
        ApplySearchStringIfUpdatedDateExists(item) ||
        item.name.toLowerCase().includes(searchString.toLowerCase()) ||
        item.description?.toLowerCase().includes(searchString.toLowerCase()) ||
        item.category?.toLowerCase().includes(searchString.toLowerCase()) ||
         item.updatedBy?.toLowerCase().includes(searchString.toLowerCase())
      );
    },

    resetSurvey() {
      this.surveyId = null;
      this.surveyTab = HeaderTabName.TAB_SURVEY_STORY;
      this.surveyName = "";
      this.previousSurveyName = "";
      this.surveyDescription = "";
      this.isStoryTabDisabled = false;
      this.surveyState = Enum.SurveyStateType.Active;
      this.isFinaliseTabDisabled = true;
      this.isSurveyFormDirty = false;
      this.surveyData = {};
      this.surveyTags = [];
      this.initialSurveyData = {};
      this.isOpenEditConfirmationPopup = false;
      this.isReloadSurvey = false;
      this.surveyFormFace = SurveyFormFace.Autoscroll;
      this.surveyStyles = null;
      this.isLoaded = false;
      this.tagOptions = [];
      this.tagSuggestions = [];
      this.isUseTemplate = false;
      this.isEditTemplate = false;
      this.isUpdatedSurveyName = false;
      this.restoreBuilder();
    },

    submit() {
      const data = {}
      data.name = this.surveyName;
      data.description = this.surveyDescription;
      data.state = Enum.SurveyStateType.Active;
      data.definitionStructure = JSON.stringify({
        formFace: this.surveyFormFace,
        styles: this.surveyStyles,
        definition: this.surveyData
      });
      data.category = JSON.stringify(this.tagOptions.map((item) => {
        return item["value"];
      }));
      if (this.surveyId) {
        return this.update(data);
      } else 
        return this.save(data);
    },

    submitTemplate(isSaveToTemplate = false) {
      const data = {}
      data.name = this.surveyName;
      data.description = this.surveyDescription;
      data.state = Enum.SurveyStateType.Active;
      data.definitionStructure = JSON.stringify({
        formFace: this.surveyFormFace,
        styles: this.surveyStyles,
        definition: this.surveyData
      });
      data.category = JSON.stringify(this.tagOptions.map((item) => {
        return item["value"];
      }));
      if (this.surveyId && !this.isUseTemplate && this.isEditTemplate) {
        return this.updateTemplate(data);
      } else 
        return this.saveTemplate(data, isSaveToTemplate);
    },

    save(data) {
      return (ApiService.add(ApiUrl.addSurvey, data).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.surveyId = response.data.result.id;
              this.isUseTemplate = false;
              this.isSurveyFormDirty = false;
              window.history.pushState("", "", `/admin/chat/story/${response.data.result.id}`);
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    update(data) {
      const updatedData = {
        ...data,
        id: this.surveyId
      };

      return (ApiService.edit(ApiUrl.updateSurvey, updatedData).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.setIsSurveyFormDirty(false);
            }
            else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    saveTemplate(data, isSaveToTemplate) {
      const url = isSaveToTemplate ? ApiUrl.saveSurveyToTemplate : ApiUrl.saveSurveyToExisting;
      return (ApiService.add(url, data).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              Alert.success(response.data.message);
              this.resetSurvey();
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    updateTemplate(data) {
      const updatedData = {
        ...data,
        id: this.surveyId
      };

      return (ApiService.edit(ApiUrl.updateSurveyTemplate, updatedData).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              Alert.success(response.data.message);
              this.setIsSurveyFormDirty(false)
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    getSurveyDetail(id) {
      return (ApiService.getDetail(ApiUrl.getSurveyDetail, id).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              const { definitionStructure, name, description, state, category } = response.data.result;
              this.setSurveyId(parseInt(id));
              this.setInitialSurveyData(JSON.parse(definitionStructure).definition);
              this.setSurveyData(JSON.parse(definitionStructure).definition);
              this.setSurveyFormFace(JSON.parse(definitionStructure).formFace ?? SurveyFormFace.Autoscroll);
              this.setSurveyStyles(JSON.parse(definitionStructure).styles);
              this.setSurveyName(name);
              this.setPreviousSurveyName(name);
              this.setSurveyDescription(description);
              this.setSurveyStateData(state);
              if (category !== undefined) {
                this.setTagOptions(JSON.parse(category).map((item) => {
                  return {
                    label: item,
                    value: item
                  }
                }));
              }
              this.setIsSurveyFormDirty(false);
              this.setIsOpenConfirmationPopup(false);
              this.setIsReloadSurvey(false);
              this.setIsUpdatedSurveyName(false);
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }

            return Promise.resolve(response);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    getSurveyTemplateDetail(id) {
      return (ApiService.getDetail(ApiUrl.getSurveyTemplateDetail, id).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              const { definitionStructure, name, description, state, category } = response.data.result;
              this.setSurveyId(parseInt(id));
              this.setInitialSurveyData(JSON.parse(definitionStructure).definition);
              this.setSurveyData(JSON.parse(definitionStructure).definition);
              this.setSurveyFormFace(JSON.parse(definitionStructure).formFace ?? SurveyFormFace.Autoscroll);
              this.setSurveyStyles(JSON.parse(definitionStructure).styles);
              this.setSurveyName(name);
              this.setPreviousSurveyName(name);
              this.setSurveyDescription(description);
              this.setSurveyStateData(state);
              if (category !== undefined) {
                this.setTagOptions(JSON.parse(category).map((item) => {
                  return {
                    label: item,
                    value: item
                  }
                }));
              }
              this.setIsSurveyFormDirty(false);
              this.setIsOpenConfirmationPopup(false);
              this.setIsReloadSurvey(false);
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }

            return Promise.resolve(response);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    getSurveyList() {
      return (ApiService.get(ApiUrl.getListSurveys).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.setSurveyList(response.data.result)
            } else 
              Alert.error("Oops, Something went wrong! Please try again.");
            return Promise.resolve(response);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    getSurveyTemplateList() {
      return (ApiService.get(ApiUrl.getSurveyTemplateList).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.setSurveyTemplateList(response.data.result)
            } else 
              Alert.error("Oops, Something went wrong! Please try again.");
            return Promise.resolve(response);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    getSurveyTags() {
      return (ApiService.get(ApiUrl.getSurveyTags).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.setSurveyTags(response.data.result)
            } else 
              Alert.error("Oops, Something went wrong! Please try again.");
            return Promise.resolve(response);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    deleteSurvey(id, activeTab) {
      const surveyDelete = ApiUrl.deleteSurvey;
      return (ApiService.remove(surveyDelete, id).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
                this.getSurveyList();
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    deleteSurveyTemplate(id) {
      return (ApiService.remove(ApiUrl.deleteSurveyTemplate, id).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              this.getSurveyTemplateList()
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    setSurveyState(id, state) {
      let params = {
        id: id,
        state: state
      };
      return (ApiService.edit(ApiUrl.setSurveyState, params).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              if (this.surveyId) {
                this.setSurveyStateData(state);
              } else {
                this.getSurveyList();
              }
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response.data);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ))
    },

    loadSurveyByToken(token) {
      return (ApiService.getDetailWithParams(ApiUrl.getSurveyByToken, token).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              if (response.data.result) {
                const { definitionStructure } = response.data.result;

                this.setSurveyData(JSON.parse(definitionStructure).definition);
                this.setSurveyFormFace(JSON.parse(definitionStructure).formFace);
                this.setSurveyStyles(JSON.parse(definitionStructure).styles);
              }
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    loadSurveyClientByToken(token) {
      return (ApiService.getDetailWithParams(ApiUrl.getSurveyClientByToken, token).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              if (response.data.result) {
                const definitionStructure = JSON.parse(response.data.result.definitionStructure);

                this.setSurveyData(definitionStructure.definition);
                this.setSurveyFormFace(definitionStructure.formFace);
                this.setSurveyStyles(definitionStructure.styles);
              }
            } else {
              Alert.error("Oops, Something went wrong! Please try again.");
            }
            return Promise.resolve(response);
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    submitSurveyResult(surveyData, dataToken) {
      const data = {
        response: convertTripettoData(surveyData),
        token: dataToken
      }
      return (ApiService.post(ApiUrl.submitSurvey, data).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              return Promise.resolve(response.data);
            }
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    submitSurveyClientResult(surveyData, dataToken) {
      const data = {
        response: convertTripettoData(surveyData),
        token: dataToken
      }
      return (ApiService.post(ApiUrl.submitSurveyClient, data).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              return Promise.resolve(response.data);
            }
          }
        )
      ).catch(
        action(
          err => {
            return Promise.reject(err);
          }
        )
      ));
    },

    getIsNameInUse(name) {
      return (ApiService.get(ApiUrl.getSurveyIsNameInUse + `?name=${name}`, "", "Failed to get survey data").then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              return Promise.resolve(response.data);
            } else {
              Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);
            }
          }
        )
      ).catch(action(err => Promise.reject(err))));
    },

    postSaveAsNew(id, name) {
      const data = {
        id: id,
        name: name,
      };

      return (ApiService.post(ApiUrl.postSurveySaveAsNew, data).then(
        action(
          response => {
            if (response && response.status === 200 && response.data && !response.data.isError) {
              return Promise.resolve(response.data);
            } else {
              Alert.error(Constants.ALERT_SOMETHING_WENT_WRONG);
            }
          }
        )
      ).catch(action(err => Promise.reject(err))));
    },
  }
);

export default surveyStore;
