import { LogoAutomations, LogoText, LogoEmail, LogoActivity, LogoLink } from '_logo';

export const RoleName =
{
  SuperAdmin: 1,
  Admin: 2,
  Marketer: 3,
  Recruiter: 4,
  Client: 5,
  Candidate: 6,
  Employee: 7,
};

export const LoginStates = {
  Loading: 'loading',
  Login: 'login',
  SetupMfa: 'setupMfa',
  ConfirmMfa: 'confirmMfa',
};


export const TextType = {
  Title: 1,
  Body: 2
};

export const CanvasNodeType =
{
  MultipleChoice: 0,
  FreeTextReply: 1,
  NoReply: 2,
  UserName: 3,
  EmailAddress: 4,
  PhoneNumber: 5,
  DocumentUpload: 6,
  NetPromoterScore: 7
};

export const ActionStep =
  [
    "Multiple Choice",
    "Free Text Reply",
    "No Reply",
    "User's Name",
    "Email Address",
    "Phone Number",
    "Document Upload",
    "Net Promoter Score",
  ];

export const Placeholder_FreeTextArea =
  [
    `Welcome aboard! We'd love to help you get started. What are looking to do first?`
    , 'What state do you live in ?'
    , 'Welcome we hope you find your visit today helpful!'
    , `What's your name ?`
    , `What's your email ?`
    , `What's the best number to reach you during the week ?`
    , `What state do you want to upload ? \n "Please upload your documents in MS Word or PDF format and ensure the file size is less than 5Mb. Alternatively, please include a link to your online documents."`
    , 'On a scale of zero to ten, how likely are you to recommend our business to a friend or colleague?'
  ];

export const UploadToS3Type = {
  Temporary: 0,
  Avatar: 1,
  Preview: 2,
  Attachment: 3,
  TemplateDesign: 4
};

export const CandidateColumnName = {
  CandidateName: 'Candidate Name',
  CurrentJobTitle: 'Current Job Title',
  CurrentEmployer: 'Current Employer',
  Location: 'Location',
  Resume: 'Resume',
  PhoneNumber: 'Phone Number',
  MobileNumber: 'Mobile Number',
  EmailAddress: 'Email Address',
  RoiAiScore: 'Roi-AI Score',
  RecruiterRating: 'Recruiter Rating',
  LastContacted: 'Last Contacted',
  RecentActivity: 'Recent Activity',
};

export const ClientColumnName = {
  ClientName: 'Contact Name',
  CurrentJobTitle: 'Job Title',
  Company: 'Company',
  PhoneNumber: 'Phone Number',
  MobileNumber: 'Mobile Number',
  EmailAddress: 'Email Address',
  RoiAiScore: 'Roi-AI Score',
};

export const CandidateColumnEnum = {
  CandidateName: 0,
  JobTitle: 1,
  CurrentEmployer: 2,
  Location: 3,
  PhoneNumber: 4,
  MobileNumber: 5,
  EmailAddress: 6,
  RoiAiScore: 7,
  Resume: 8,
  RecruiterRating: 9,
  LastContacted: 10,
  RecentActivity: 11,
};

export const ClientColumnEnum = {
  CandidateName: 0,
  JobTitle: 1,
  Organisation: 2,
  Location: 3,
  PhoneNumber: 4,
  EmailAddress: 5,
  RecruiterRating: 6,
  TotalJobPosted: 7,
  LastContacted: 8,
  RecentActivity: 9,
  RoiAiScore: 10,
  Industry: 11
};

export const JobColumnEnum = {
  JobTitle: 0,
  Organisation: 1,
  Status: 2,
  Location: 3,
  Source: 4,
  ClientName: 5,
  Industry: 6
};

export const JobCandidateColumnEnum = {
  CandidateName: 0,
  CandidateTitle: 1,
  Tactic: 2,
  Status: 3,
  RoiAiScore: 4,
  RecruiterRating: 5,
  Resume: 6,
  Type: 7
};

export const LeadColumnEnum = {
  LeadName: 0,
  JobTitle: 1,
  CurrentEmployer: 2,
  Industry: 3,
  Location: 4,
  PhoneNumber: 5,
  EmailAddress: 6,
  RecruiterRating: 7,
  LastContacted: 8,
  RecentActivity: 9,
  RoiAiScore: 10,
};

export const TabNameEnum = {
  Candidate: 1,
  Client: 2,
  Job: 3,
  Lead: 4,
  CandidateAudience: 5
};

export const storyType = {
  Website: 0,
  Registration: 1,
  DataCleanse: 2,
  Survey: 3,
  All: 4,
};

export const ValidationStatus = {
  Valid: 0,
  InvalidLength: 1,
  InvalidNumber: 2,
  InvalidEmail: 3,
};

export const ContactType = {
  Candidate: 1,
  Client: 2,
  Lead: 3
};

export const FinalizeWidgetPlacement = {
  BottomLeft: 0,
  BottomRight: 1
};

export const MonthList = {
  January: 0,
  February: 1,
  March: 2,
  April: 3,
  May: 4,
  June: 5,
  July: 6,
  August: 7,
  September: 8,
  October: 9,
  November: 10,
  December: 11
}

export const SortingFilter = {
  OldestToNewest: 1,
  NewestToOldest: 2,
  AZ: 3,
  ZA: 4
}

export const searchTypeFilter = {
  All: 0,
  Company: 1,
  User: 2
}

export const CandidateAudienceColumnEnum = {
  AudienceName: 0,
  AudienceType: 1,
  Status: 2,
  TotalMembers: 3,
  Source: 4,
  Created: 5,
  LastAddedDate: 6,
  LastActivityDate: 7,
  LastActivity: 8,
  ActionButton: 9
};

export const AudienceType = {
  Automatic: 1,
  Manual: 2
};

export const CampaignBlastMethod = {
  Default: 0,
  Email: 1,
  Text: 2,
  Whatsapp: 3,
  Survey: 6,
};

export const FeatureType = {
  Email: 1,
  Sms: 2,
  Whatsapp: 3,
  Form: 4,
  EmailLink: 5,
  Survey: 6,
  WebActivityTracker: 7,
  SMSLink: 8,
};

export const ActivityFeatureType = {
  1: {
    text: "Email",
    icon: LogoEmail
  },
  2: {
    text: "SMS",
    icon: LogoText
  },
  3: {
    text: "Whatsapp",
    icon: LogoText
  },
  4: {
    text: "Form",
    icon: LogoEmail
  },
  5: {
    text: "Email Link",
    icon: LogoLink
  },
  6: {
    text: "Survey",
    icon: LogoAutomations
  },
  7: {
    text: "Web activity tracking",
    icon: LogoActivity
  },
  8: {
    text: "SMS Link",
    icon: LogoLink
  },
};

export const PlacementOptions = [
  {
    value: "temporary",
    text: "Temporary"
  },
  {
    value: "contract",
    text: "Contract"
  },
  {
    value: "permanent",
    text: "Permanent"
  },
  {
    value: "credit",
    text: "Credit"
  },
];

export const PlacementOptionsEndDate = [
  {
    value: "temporary",
    text: "Temporary"
  },
  {
    value: "contract",
    text: "Contract"
  },
];

export const LinkValidityType = {
  Minute: 0,
  Hour: 1,
  Day: 2
};

export const DelayTypeCandidate = {
  CandidateBased: 1
}

export const DelayTypeClient = {
  ClientBased: 1
}

export const DelayTypeNameCandidate = {
  CandidateBased: 'Candidate Based'
}

export const DelayTypeNameClient = {
  ClientBased: 'Contact Based'
}

export const TriggerType = {
  CandidateBased: 1,
  PlacementBased: 2,
  JobBased: 3
}

export const TriggerTypeClient = {
  ClientBased: 1,
  PlacementBased: 2,
  JobBased: 3,
}

export const TriggerTypeList = [
  {
    id: 1,
    name: "Candidate based"
  },
  {
    id: 2,
    name: "Placement based"
  },
  {
    id: 3,
    name: "Job based"
  }
]

export const LegacyTriggerTypeList = [
  {
    id: 1,
    name: "Candidate based"
  },
  {
    id: 2,
    name: "Placement based"
  }
]

export const TriggerTypeListClient = [
  {
    id: 1,
    name: "Contact based"
  },
  {
    id: 2,
    name: "Placement based"
  },
  {
    id: 3,
    name: "Job based"
  }
]

export const LegacyTriggerTypeListClient = [
  {
    id: 1,
    name: "Contact based"
  },
  {
    id: 2,
    name: "Placement based"
  }
]

export const TriggerList = {
  CandidateSubmitForm: 1,
  JobApplicationReceived: 2,
  JobApplicationRejection: 3,
  PlacementDateBased: 4,
  NurturePassiveCandidate: 5,
  ManualUpdateByUser: 6,
  CandidateStatusChanged: 7,
  RoiAiActivityScore: 8,
  CandidatePlacementStartDate: 9,
  UpdateResume: 10,
  CandidateNote: 11,
  RoiAiActivity: 12,
  CandidateCustomFieldList: 13,
  CandidateCustomFieldMultiSelectList: 14,
  CandidatePlacementEndDate: 15,
  CandidatePlacementCreatedDate: 16,
  EmailAddress: 17,
  MobileNumber: 18,
  Birthday: 19,
  Availability: 20,
  ApplicationStatus: 21,
  JobStatus: 22,
  JobJobApplicationReceived: 23,
  PlacementJobApplicationReceived: 24,
  InterviewDate: 25,
  DateCreated: 26,
  DateUpdated: 27,
  CandidateCountry: 28,
  SecondaryEmailAddress: 29,
  PhoneNumber: 30,
  SurveyScore: 31,
};

export const TriggerListClient = {
  RoiAiActivity: 1,
  ClientPlacementStartDate: 2,
  ClientNote: 3,
  ClientStatus: 4,
  ClientPlacementCreatedDate: 5,
  ClientPlacementEndDate: 6,
  ClientCustomFieldList: 7,
  ClientCustomFieldMultiSelectList: 8,
  JobStatus: 9,
  ClientJobInterviewDate: 10,
  DateCreated: 11,
  DateUpdated: 12,
  RoiAiActivityScore: 13,
  SurveyScore: 14,
  };

export const RoiAiActivityDetailsOption = {
  All: {
    Id: 0,
    Name: "All"
  },
  EmailIsDelivered: {
    Id: 6,
    Name: "Email is delivered"
  },
  EmailIsInvalid: {
    Id: 23,
    Name: "Email is invalid"
  },
  EmailIsBounced: {
    Id: 4,
    Name: "Email Bounced"
  },
  EmailUnsubscribe: {
    Id: 15,
    Name: "Email Unsubscribe"
  },
  EmailIsOpened: {
    Id: 5,
    Name: "Email Opened"
  },
  EmailLinkIsClicked: {
    Id: 17,
    Name: "Email link is clicked"
  },
  SMSIsSent: {
    Id: 7,
    Name: "SMS Sent"
  },
  SMSIsDelivered: {
    Id: 8,
    Name: "SMS Delivered"
  },
  SMSIsInvalid: {
    Id: 24,
    Name: "SMS Invalid"
  },
  TextUnsubscribe: {
    Id: 16,
    Name: "SMS Unsubscribe"
  },
  TextLinkIsClicked: {
    Id: 26,
    Name: "SMS Link Click"
  },
  SurveyIsOpened: {
    Id: 18,
    Name: "Survey Opened"
  },
  SurveyIsSubmited: {
    Id: 19,
    Name: "Survey Completed"
  },
  WebActivityTracker_PageView: {
    Id: 22,
    Name: "Web Activity - Page View"
  },
  WebActivityTracker_JobView: {
    Id: 20,
    Name: "Web Activity - Job View"
  },
  WebActivityTracker_ApplicationView: {
    Id: 21,
    Name: "Web Activity - Application View"
  },
  WebActivityTracker_CustomView: {
    Id: 25,
    Name: "Web Activity - Custom View"
  },
};

export const AutomationRoiActivityList = [
  // {
  //   id: 3,
  //   name: "Email is sent",
  //   type: CampaignBlastMethod.Email
  // },
  {
    //Add 'Any' into dropdown options #8372
    id: 0,
    name: "Any",
    type: CampaignBlastMethod.Email
  },
  {
    id: 6,
    name: "Email Delivered",
    type: CampaignBlastMethod.Email
  },
  {
    id: 23,
    name: "Email Invalid",
    type: CampaignBlastMethod.Email
  },
  {
    id: 4,
    name: "Email Bounced",
    type: CampaignBlastMethod.Email
  },
  {
    id: 15,
    name: "Email Unsubscribe",
    type: CampaignBlastMethod.Email
  },
  {
    id: 5,
    name: "Email Opened",
    type: CampaignBlastMethod.Email
  },
  {
    id: 17,
    name: "Email Link Click",
    linkType: FeatureType.EmailLink
  },
  {
    id: 7,
    name: "SMS Sent",
    type: CampaignBlastMethod.Text
  },
  {
    id: 8,
    name: "SMS Delivered",
    type: CampaignBlastMethod.Text
  },
  {
    id: 24,
    name: "SMS Invalid",
    type: CampaignBlastMethod.Text
  },
  {
    id: 16,
    name: "SMS Unsubscribe",
    type: CampaignBlastMethod.Text
  },  
  {
    id: 26,
    name: "SMS Link Clicked",
    linkType: FeatureType.SMSLink
  },
  {
    id: 18,
    name: "Survey Opened",
    type: CampaignBlastMethod.Survey
  },
  {
    id: 19,
    name: "Survey Completed",
    type: CampaignBlastMethod.Survey
  },
  {
    id: 22,
    name: "Web Activity - Page View"
  },
  {
    id: 20,
    name: "Web Activity - Job View"
  },
  {
    id: 21,
    name: "Web Activity - Application View"
  },
];

export const AutomationRoiActivityListClient = [
  // {
  //   id: 3,
  //   name: "Email is sent",
  //   type: CampaignBlastMethod.Email
  // },
  {
    //Add 'Any' into dropdown options #8372
    id: 0,
    name: "Any",
    type: CampaignBlastMethod.Email
  },
  {
    id: 6,
    name: "Email Delivered",
    type: CampaignBlastMethod.Email
  },
  {
    id: 23,
    name: "Email Invalid",
    type: CampaignBlastMethod.Email
  },
  {
    id: 4,
    name: "Email Bounced",
    type: CampaignBlastMethod.Email
  },
  {
    id: 15,
    name: "Email Unsubscribe",
    type: CampaignBlastMethod.Email
  },
  {
    id: 5,
    name: "Email Opened",
    type: CampaignBlastMethod.Email
  },
  {
    id: 17,
    name: "Email Link Click",
    linkType: FeatureType.EmailLink
  },
  {
    id: 18,
    name: "Survey Opened",
    type: CampaignBlastMethod.Survey
  },
  {
    id: 19,
    name: "Survey Completed",
    type: CampaignBlastMethod.Survey
  },
  {
    id: 22,
    name: "Web Activity - Page View"
  },
  {
    id: 25,
    name: "Web Activity - Custom View"
  },
];

export const AutomationLoopingType = {
  ReEntryRestriction: 1,
  Looping: 2,
};

export const AutomationTimeOperator = {
  Equal: "Equals",
  AnyDate: "Any Date",
  LessThan: "Less Than",
  MoreThan: "More Than",
  Before: "Before",
  On: "On",
  After: "After",
  Has: "Has",
  HasNot: "Has Not",
  Is: "Is",
  IsNot: "Is Not",
  OnStartDate: "On Start Date", // for patching old data
  GreaterThan: "Greater Than",
  Exists: "Exists",
  IsEmpty: "Is Empty"
};

export const InterviewTypeOptions = [
  {
    value: "Internal",
    text: "Internal"
  },
  {
    value: "External",
    text: "External"
  }
]

export const AutomationTriggerListJobBased = [
  {
    triggerId: TriggerList.ApplicationStatus,
    customFieldTriggerId: null,
    triggerName: "Application status",
    operators: [
      {
        operatorId: 1,
        operatorName: AutomationTimeOperator.Is
      },
      {
        operatorId: 2,
        operatorName: AutomationTimeOperator.IsNot
      },
    ],
    thirdColumn: {
      options: [
        {
          operatorId: 1,
          operatorName: AutomationTimeOperator.AnyDate
        },
        {
          operatorId: 2,
          operatorName: AutomationTimeOperator.LessThan
        },
        {
          operatorId: 3,
          operatorName: AutomationTimeOperator.MoreThan
        },
      ],
      jobApplicationStatuses: [],
      operatorId: 2,
      operatorName: AutomationTimeOperator.LessThan,
      jobApplicationStatusIds: [],
      jobApplicationStatusNames: "",
      months: 0,
      weeks: 0,
      days: 0,
      hours: 1,
      hideTimeCondition: true,
    }
  },
  {
    triggerId: TriggerList.JobStatus,
    customFieldTriggerId: null,
    triggerName: "Job status",
    operators: [
      {
        operatorId: 1,
        operatorName: AutomationTimeOperator.Is
      },
      {
        operatorId: 2,
        operatorName: AutomationTimeOperator.IsNot
      },
    ],
    thirdColumn: {
      options: [
        {
          operatorId: 1,
          operatorName: AutomationTimeOperator.AnyDate
        },
        {
          operatorId: 2,
          operatorName: AutomationTimeOperator.LessThan
        },
        {
          operatorId: 3,
          operatorName: AutomationTimeOperator.MoreThan
        },
      ],
      jobStatuses: [],
      operatorId: 2,
      operatorName: AutomationTimeOperator.LessThan,
      jobStatusIds: [],
      jobStatusNames: "",
      months: 0,
      weeks: 0,
      days: 0,
      hours: 1,
      hideTimeCondition: true,
    }
  },
  {
    triggerId: TriggerList.InterviewDate,
    customFieldTriggerId: null,
    triggerName: "Interview Date",
    operators: [
      {
        operatorId: 1,
        operatorName: AutomationTimeOperator.Before
      },
      {
        operatorId: 2,
        operatorName: AutomationTimeOperator.On
      },
      {
        operatorId: 3,
        operatorName: AutomationTimeOperator.After
      },
    ],
    thirdColumn: {
      options: [],
      interviews: [],
      interviewTypeOptions: InterviewTypeOptions,
      months: 0,
      weeks: 0,
      days: 0,
      hours: 0
    }
  },
  {
    triggerId: TriggerList.JobJobApplicationReceived,
    customFieldTriggerId: null,
    triggerName: "Job application received",
    operators: [
      {
        operatorId: 1,
        operatorName: AutomationTimeOperator.Is
      },
      {
        operatorId: 2,
        operatorName: AutomationTimeOperator.IsNot
      },
    ],
    thirdColumn: {
      options: [
        {
          operatorId: 1,
          operatorName: AutomationTimeOperator.AnyDate
        },
        {
          operatorId: 2,
          operatorName: AutomationTimeOperator.LessThan
        },
        {
          operatorId: 3,
          operatorName: AutomationTimeOperator.MoreThan
        },
      ],
      jobSources: [],
      operatorId: 2,
      operatorName: AutomationTimeOperator.LessThan,
      jobIds: [],
      jobNames: "",
      months: 0,
      weeks: 0,
      days: 1,
      hours: 0,
      hideTimeCondition: true,
    }
  },
  {
    triggerId: TriggerList.SurveyScore,
    customFieldTriggerId: null,
    triggerName: "Survey score",
    operators:[],
    thirdColumn: {
      options: [
        {
          operatorId: 1,
          operatorName: AutomationTimeOperator.Equal
        },
        {
          operatorId: 2,
          operatorName: AutomationTimeOperator.LessThan
        },
        {
          operatorId: 3,
          operatorName: AutomationTimeOperator.MoreThan
        }
      ],
      surveyList: [],
      ids: [],
      score: 0,
      isActivityAllSelected: false
    }
  },
];

export const AutomationTriggerListJobBasedClient = [
  {
    triggerId: TriggerListClient.JobStatus,
    customFieldTriggerId: null,
    triggerName: "Job status",
    operators: [
      {
        operatorId: 1,
        operatorName: AutomationTimeOperator.Is
      },
      {
        operatorId: 2,
        operatorName: AutomationTimeOperator.IsNot
      },
    ],
    thirdColumn: {
      options: [
        {
          operatorId: 1,
          operatorName: AutomationTimeOperator.AnyDate
        },
        {
          operatorId: 2,
          operatorName: AutomationTimeOperator.LessThan
        },
        {
          operatorId: 3,
          operatorName: AutomationTimeOperator.MoreThan
        },
      ],
      jobStatuses: [],
      operatorId: 2,
      operatorName: AutomationTimeOperator.LessThan,
      jobStatusIds: [],
      jobStatusNames: "",
      months: 0,
      weeks: 0,
      days: 0,
      hours: 1,
      hideTimeCondition: true,
    }
  },
  {
    triggerId: TriggerListClient.SurveyScore,
    customFieldTriggerId: null,
    triggerName: "Survey score",
    operators:[],
    thirdColumn: {
      options: [
        {
          operatorId: 1,
          operatorName: AutomationTimeOperator.Equal
        },
        {
          operatorId: 2,
          operatorName: AutomationTimeOperator.LessThan
        },
        {
          operatorId: 3,
          operatorName: AutomationTimeOperator.MoreThan
        }
      ],
      surveyList: [],
      ids: [],
      score: 0,
      isActivityAllSelected: false
    }
  },
];

export const AutomationTriggerListPlacementBased = [
  {
    triggerId: 9,
    customFieldTriggerId: null,
    triggerName: "Start date",
    operators: [
      {
        operatorId: 1,
        operatorName: AutomationTimeOperator.Before
      },
      {
        operatorId: 2,
        operatorName: AutomationTimeOperator.On
      },
      {
        operatorId: 3,
        operatorName: AutomationTimeOperator.After
      },
      {
        operatorId: 7,
        operatorName: AutomationTimeOperator.GreaterThan
      },
    ],
    thirdColumn: {
      options: [],
      placements: [],
      placementOptions: PlacementOptions,
      months: 0,
      weeks: 0,
      days: 0,
      hours: 0
    }
  },
  {
    triggerId: 15,
    customFieldTriggerId: null,
    triggerName: "End date",
    operators: [
      {
        operatorId: 1,
        operatorName: AutomationTimeOperator.Before
      },
      {
        operatorId: 2,
        operatorName: AutomationTimeOperator.On
      },
      {
        operatorId: 3,
        operatorName: AutomationTimeOperator.After
      },
      {
        operatorId: 4,
        operatorName: AutomationTimeOperator.LessThan
      },
      {
        operatorId: 5,
        operatorName: AutomationTimeOperator.MoreThan
      },
    ],
    thirdColumn: {
      options: [],
      placements: [],
      placementOptions: PlacementOptionsEndDate,
      months: 0,
      weeks: 0,
      days: 0,
      hours: 0
    }
  },
  {
    triggerId: 16,
    customFieldTriggerId: null,
    triggerName: "Created date",
    operators: [
      {
        operatorId: 6,
        operatorName: AutomationTimeOperator.AnyDate
      },
      {
        operatorId: 4,
        operatorName: AutomationTimeOperator.LessThan
      },
      {
        operatorId: 5,
        operatorName: AutomationTimeOperator.MoreThan
      },
    ],
    thirdColumn: {
      options: [],
      placements: [],
      placementOptions: PlacementOptions,
      months: 0,
      weeks: 0,
      days: 0,
      hours: 1,
      hideTimeCondition: true,
    }
  },
  {
    triggerId: TriggerList.SurveyScore,
    customFieldTriggerId: null,
    triggerName: "Survey score",
    operators:[],
    thirdColumn: {
      options: [
        {
          operatorId: 1,
          operatorName: AutomationTimeOperator.Equal
        },
        {
          operatorId: 2,
          operatorName: AutomationTimeOperator.LessThan
        },
        {
          operatorId: 3,
          operatorName: AutomationTimeOperator.MoreThan
        }
      ],
      surveyList: [],
      ids: [],
      score: 0,
      isActivityAllSelected: false
    }
  },
];

export const AutomationTriggerListClientPlacementBased = [
  {
    triggerId: 2,
    customFieldTriggerId: null,
    triggerName: "Start Date",
    operators: [
      {
        operatorId: 1,
        operatorName: AutomationTimeOperator.Before
      },
      {
        operatorId: 2,
        operatorName: AutomationTimeOperator.On
      },
      {
        operatorId: 3,
        operatorName: AutomationTimeOperator.After
      },
    ],
    thirdColumn: {
      options: [],
      placements: [],
      placementOptions: PlacementOptions,
      months: 0,
      weeks: 0,
      days: 0,
      hours: 0
    }
  },
  {
    triggerId: 5,
    customFieldTriggerId: null,
    triggerName: "Created Date",
    operators: [
      {
        operatorId: 6,
        operatorName: AutomationTimeOperator.AnyDate
      },
      {
        operatorId: 4,
        operatorName: AutomationTimeOperator.LessThan
      },
      {
        operatorId: 5,
        operatorName: AutomationTimeOperator.MoreThan
      },
    ],
    thirdColumn: {
      options: [],
      placements: [],
      placementOptions: PlacementOptions,
      months: 0,
      weeks: 0,
      days: 0,
      hours: 1,
      hideTimeCondition: true,
    }
  },
  {
    triggerId: 6,
    customFieldTriggerId: null,
    triggerName: "End Date",
    operators: [
      {
        operatorId: 1,
        operatorName: AutomationTimeOperator.Before
      },
      {
        operatorId: 2,
        operatorName: AutomationTimeOperator.On
      },
      {
        operatorId: 3,
        operatorName: AutomationTimeOperator.After
      },
      {
        operatorId: 4,
        operatorName: AutomationTimeOperator.LessThan
      },
      {
        operatorId: 5,
        operatorName: AutomationTimeOperator.MoreThan
      },
    ],
    thirdColumn: {
      options: [],
      placements: [],
      placementOptions: PlacementOptions,
      months: 0,
      weeks: 0,
      days: 0,
      hours: 0
    }
  },
  {
    triggerId: TriggerListClient.SurveyScore,
    customFieldTriggerId: null, 
    triggerName: "Survey score",
    operators: [
    
    ],
    thirdColumn: {
      options: [
        {
          operatorId: 1,
          operatorName: AutomationTimeOperator.Equal
        },
        {
          operatorId: 2,
          operatorName: AutomationTimeOperator.LessThan  
        },
        {
          operatorId: 3, 
          operatorName: AutomationTimeOperator.MoreThan
        }
      ],
      surveyList: [],
      ids: [],
      score: 0,
      isActivityAllSelected: false
    }
  },
];

export const AutomationTriggerCustomList = {
  triggerId: 0,
  customFieldTriggerId: 0,
  triggerName: "",
  operators: [
    {
      operatorId: 1,
      operatorName: AutomationTimeOperator.Is
    },
    {
      operatorId: 2,
      operatorName: AutomationTimeOperator.IsNot
    },
  ],
  thirdColumn: {
    isMultiSelect: false,
    fieldId: 0,
    ids: [],
    names: "",
    options: [],
    customFieldValues: [],
    customFieldNames: "",
  }
};

export const AutomationTriggerList = [
  {
    triggerId: 12,
    customFieldTriggerId: null,
    triggerName: "Activity",
    operators: [
      {
        operatorId: 1,
        operatorName: AutomationTimeOperator.Is
      },
      {
        operatorId: 2,
        operatorName: AutomationTimeOperator.IsNot
      },
    ],
    thirdColumn: {
      months: 0,
      weeks: 0,
      days: 1,
      hours: 0,
      activities: [],
      names: "",
      activityOptions: AutomationRoiActivityList,
      subActivities: [],
      subActivityNames: "",
      hideTimeCondition: true,
    },
  },
  {
    triggerId: 8,
    customFieldTriggerId: null,
    triggerName: "Roi score",
    operators: [
      {
        operatorId: 1,
        operatorName: AutomationTimeOperator.Equal
      },
      {
        operatorId: 2,
        operatorName: AutomationTimeOperator.LessThan
      },
      {
        operatorId: 3,
        operatorName: AutomationTimeOperator.MoreThan
      },
    ],
    thirdColumn: {
      score: 0
    }
  },
  {
    triggerId: TriggerList.SurveyScore,
    customFieldTriggerId: null, 
    triggerName: "Survey score",
    operators: [
    
    ],
    thirdColumn: {
      options: [
        {
          operatorId: 1,
          operatorName: AutomationTimeOperator.Equal
        },
        {
          operatorId: 2,
          operatorName: AutomationTimeOperator.LessThan  
        },
        {
          operatorId: 3, 
          operatorName: AutomationTimeOperator.MoreThan
        }
      ],
      surveyList: [],
      ids: [],
      score: 0,
      isActivityAllSelected: false
    }
  },
  {
    triggerId: 7,
    customFieldTriggerId: null,
    triggerName: "Status",
    operators: [
      {
        operatorId: 1,
        operatorName: AutomationTimeOperator.Is
      },
      {
        operatorId: 2,
        operatorName: AutomationTimeOperator.IsNot
      },
    ],
    thirdColumn: {
      options: [
        {
          operatorId: 1,
          operatorName: AutomationTimeOperator.AnyDate
        },
        {
          operatorId: 2,
          operatorName: AutomationTimeOperator.LessThan
        },
        {
          operatorId: 3,
          operatorName: AutomationTimeOperator.MoreThan
        },
      ],
      candidateStatuses: [],
      operatorId: 2,
      operatorName: AutomationTimeOperator.LessThan,
      ids: [],
      names: "",
      months: 0,
      weeks: 0,
      days: 1,
      hours: 0,
      hideTimeCondition: true,
    }
  },
  {
    triggerId: 11,
    customFieldTriggerId: null,
    triggerName: "Notes",
    operators: [
      {
        operatorId: 1,
        operatorName: AutomationTimeOperator.Has
      },
      {
        operatorId: 2,
        operatorName: AutomationTimeOperator.HasNot
      },
    ],
    thirdColumn: {
      noteTypes: [],
      displayNoteTypes: "",
      options: [],
      months: 0,
      weeks: 0,
      days: 1,
      hours: 0,
      hideTimeCondition: true,
    }
  },
  {
    triggerId: TriggerList.DateCreated,
    customFieldTriggerId: null,
    triggerName: "Date created",
    operators: [
      {
        operatorId: 1,
        operatorName: AutomationTimeOperator.MoreThan
      },
      {
        operatorId: 2,
        operatorName: AutomationTimeOperator.LessThan
      },
    ],
    thirdColumn: {
      months: 0,
      weeks: 0,
      days: 1,
      hours: 0,
      hideTimeCondition: true,
    }
  },
  {
    triggerId: TriggerList.DateUpdated,
    customFieldTriggerId: null,
    triggerName: "Date updated",
    operators: [
      {
        operatorId: 1,
        operatorName: AutomationTimeOperator.MoreThan
      },
      {
        operatorId: 2,
        operatorName: AutomationTimeOperator.LessThan
      },
    ],
    thirdColumn: {
      months: 0,
      weeks: 0,
      days: 1,
      hours: 0,
      hideTimeCondition: true,
    }
  },
  {
    triggerId: 2,
    customFieldTriggerId: null,
    triggerName: "Job application date",
    operators: [
      {
        operatorId: 1,
        operatorName: AutomationTimeOperator.Is
      },
      {
        operatorId: 2,
        operatorName: AutomationTimeOperator.IsNot
      },
    ],
    thirdColumn: {
      options: [
        {
          operatorId: 1,
          operatorName: AutomationTimeOperator.AnyDate
        },
        {
          operatorId: 2,
          operatorName: AutomationTimeOperator.LessThan
        },
        {
          operatorId: 3,
          operatorName: AutomationTimeOperator.MoreThan
        },
      ],
      jobSources: [],
      operatorId: 2,
      operatorName: AutomationTimeOperator.LessThan,
      jobIds: [],
      jobNames: "",
      months: 0,
      weeks: 0,
      days: 1,
      hours: 0,
      hideTimeCondition: true,
    }
  },
  {
    triggerId: 10,
    customFieldTriggerId: null,
    triggerName: "Resume date",
    operators: [
      {
        operatorId: 1,
        operatorName: AutomationTimeOperator.MoreThan
      },
      {
        operatorId: 2,
        operatorName: AutomationTimeOperator.LessThan
      },
    ],
    thirdColumn: {
      months: 0,
      weeks: 0,
      days: 1,
      hours: 0,
      hideTimeCondition: true,
    }
  },
  {
    triggerId: TriggerList.PhoneNumber,
    customFieldTriggerId: null,
    triggerName: "Phone",
    operators: [
      {
        operatorId: 1,
        operatorName: AutomationTimeOperator.Exists
      },
      {
        operatorId: 2,
        operatorName: AutomationTimeOperator.IsEmpty
      }
    ],
    thirdColumn: {}
  },
  {
    triggerId: TriggerList.MobileNumber,
    customFieldTriggerId: null,
    triggerName: "Mobile",
    operators: [
      {
        operatorId: 1,
        operatorName: AutomationTimeOperator.Exists
      },
      {
        operatorId: 2,
        operatorName: AutomationTimeOperator.IsEmpty
      }
    ],
    thirdColumn: {}
  },
  {
    triggerId: TriggerList.EmailAddress,
    customFieldTriggerId: null,
    triggerName: "Email",
    operators: [
      {
        operatorId: 1,
        operatorName: AutomationTimeOperator.Exists
      },
      {
        operatorId: 2,
        operatorName: AutomationTimeOperator.IsEmpty
      }
    ],
    thirdColumn: {}
  },
  {
    triggerId: TriggerList.SecondaryEmailAddress,
    customFieldTriggerId: null,
    triggerName: "Secondary email",
    operators: [
      {
        operatorId: 1,
        operatorName: AutomationTimeOperator.Exists
      },
      {
        operatorId: 2,
        operatorName: AutomationTimeOperator.IsEmpty
      }
    ],
    thirdColumn: {}
  },
  {
    triggerId: TriggerList.Availability,
    customFieldTriggerId: null,
    triggerName: "Availability",
    operators: [
      {
        operatorId: 1,
        operatorName: AutomationTimeOperator.Before
      },
      {
        operatorId: 2,
        operatorName: AutomationTimeOperator.On
      },
      {
        operatorId: 3,
        operatorName: AutomationTimeOperator.After
      },
    ],
    thirdColumn: {}
  },
  {
    triggerId: TriggerList.CandidateCountry,
    customFieldTriggerId: null,
    triggerName: "Country",
    operators: [
      {
        operatorId: 1,
        operatorName: AutomationTimeOperator.Is
      },
      {
        operatorId: 2,
        operatorName: AutomationTimeOperator.IsNot
      },
      {
        operatorId: 3,
        operatorName: AutomationTimeOperator.Exists
      },
      {
        operatorId: 4,
        operatorName: AutomationTimeOperator.IsEmpty
      }
    ],
    thirdColumn: {
      countries: [],
      names: "",
      options: []
    }
  },
  {
    triggerId: TriggerList.Birthday,
    customFieldTriggerId: null,
    triggerName: "Birthday",
    operators: [
      {
        operatorId: 1,
        operatorName: AutomationTimeOperator.Before
      },
      {
        operatorId: 2,
        operatorName: AutomationTimeOperator.On
      },
      {
        operatorId: 3,
        operatorName: AutomationTimeOperator.After
      },
    ],
    thirdColumn: {
      months: 0,
      weeks: 0,
      days: 0,
      hours: 0
    }
  },
];

export const LastAutomationTriggerCandidateId = AutomationTriggerList[AutomationTriggerList.length - 1].triggerId;

export const AutomationTriggerListClient = [
  {
    triggerId: 1,
    customFieldTriggerId: null,
    triggerName: "Activity",
    operators: [
      {
        operatorId: 1,
        operatorName: AutomationTimeOperator.Is
      },
      {
        operatorId: 2,
        operatorName: AutomationTimeOperator.IsNot
      },
    ],
    thirdColumn: {
      months: 0,
      weeks: 0,
      days: 1,
      hours: 0,
      activities: [],
      names: "",
      activityOptions: AutomationRoiActivityListClient,
      subActivities: [],
      subActivityNames: "",
      hideTimeCondition: true,
    },
  },
  {
    triggerId: TriggerListClient.SurveyScore,
    customFieldTriggerId: null, 
    triggerName: "Survey score",
    operators: [
    
    ],
    thirdColumn: {
      options: [
        {
          operatorId: 1,
          operatorName: AutomationTimeOperator.Equal
        },
        {
          operatorId: 2,
          operatorName: AutomationTimeOperator.LessThan  
        },
        {
          operatorId: 3, 
          operatorName: AutomationTimeOperator.MoreThan
        }
      ],
      surveyList: [],
      ids: [],
      score: 0,
      isActivityAllSelected: false
    }
  },
  {
    triggerId: 4,
    customFieldTriggerId: null,
    triggerName: "Status",
    operators: [
      {
        operatorId: 1,
        operatorName: AutomationTimeOperator.Is
      },
      {
        operatorId: 2,
        operatorName: AutomationTimeOperator.IsNot
      },
    ],
    thirdColumn: {
      ids: [],
      names: "",
      options: [
        {
          operatorId: 1,
          operatorName: AutomationTimeOperator.AnyDate
        },
        {
          operatorId: 2,
          operatorName: AutomationTimeOperator.LessThan
        },
        {
          operatorId: 3,
          operatorName: AutomationTimeOperator.MoreThan
        }
      ],
      candidateStatuses: [],
      operatorId: 2,
      operatorName: AutomationTimeOperator.LessThan,
      months: 0,
      weeks: 0,
      days: 1,
      hours: 0,
      hideTimeCondition: true,
    }
  },
  {
    triggerId: 3,
    customFieldTriggerId: null,
    triggerName: "Notes",
    operators: [
      {
        operatorId: 1,
        operatorName: AutomationTimeOperator.Has
      },
      {
        operatorId: 2,
        operatorName: AutomationTimeOperator.HasNot
      },
    ],
    thirdColumn: {
      noteTypes: [],
      displayNoteTypes: "",
      options: [],
      months: 0,
      weeks: 0,
      days: 1,
      hours: 0,
      hideTimeCondition: true,
    }
  },
  {
    triggerId: TriggerListClient.DateCreated,
    customFieldTriggerId: null,
    triggerName: "Date created",
    operators: [
      {
        operatorId: 1,
        operatorName: AutomationTimeOperator.MoreThan
      },
      {
        operatorId: 2,
        operatorName: AutomationTimeOperator.LessThan
      },
    ],
    thirdColumn: {
      months: 0,
      weeks: 0,
      days: 1,
      hours: 0,
      hideTimeCondition: true,
    }
  },
  {
    triggerId: TriggerListClient.DateUpdated,
    customFieldTriggerId: null,
    triggerName: "Date updated",
    operators: [
      {
        operatorId: 1,
        operatorName: AutomationTimeOperator.MoreThan
      },
      {
        operatorId: 2,
        operatorName: AutomationTimeOperator.LessThan
      },
    ],
    thirdColumn: {
      months: 0,
      weeks: 0,
      days: 1,
      hours: 0,
      hideTimeCondition: true,
    }
  },
  {
    triggerId: TriggerListClient.RoiAiActivityScore,
    customFieldTriggerId: null,
    triggerName: "Roi score",
    operators: [
      {
        operatorId: 1,
        operatorName: AutomationTimeOperator.Equal
      },
      {
        operatorId: 2,
        operatorName: AutomationTimeOperator.LessThan
      },
      {
        operatorId: 3,
        operatorName: AutomationTimeOperator.MoreThan
      },
    ],
    thirdColumn: {
      score: 0
    }
  },
];

export const AutomationDelayFirstColumnType = {
  RoiAiActivity: {
    Id: 1,
    Name: "Activity",
  },
};

export const AutomationDelayFirstColumnTypeClient = {
  RoiAiActivity: {
    Id: 1,
    Name: "Activity",
  },
};

export const AutomationIfElseFirstColumnType = {
  RoiAiActivity: 1,
  CandidateNote: 2,
  CandidateStatus: 3,
  NetPromoterScore: 4,
  Birthday: 5,
  ApplicationStatus: 6,
  DateCreated: 7,
  DateUpdated: 8,
  PlacementStatus: 9,
  SurveyScore: 10,
};

export const AutomationIfElseFirstColumnTypeClient = {
  RoiAiActivity: 1,
  ClientStatus: 2,
  ClientNote: 3,
  DateCreated: 4,
  DateUpdated: 5,
  PlacementStatus: 6,
  SurveyScore: 11,
};

export const AutomationIfElseFirstColumnClient = [
  {
    ifElseFirstColumnId: 1,
    ifElseFirstColumnName: "Activity",
    operators: [
      {
        operatorId: 1,
        operatorName: AutomationTimeOperator.Is
      },
      {
        operatorId: 2,
        operatorName: AutomationTimeOperator.IsNot
      },
    ],
    thirdColumn: {
      activities: [],
      names: "",
      activityOptions: AutomationRoiActivityListClient,
      subActivities: [],
      subActivityNames: ""
    }
  },
  {
    ifElseFirstColumnId: 2,
    ifElseFirstColumnName: "Status",
    operators: [
      {
        operatorId: 1,
        operatorName: AutomationTimeOperator.Is
      },
      {
        operatorId: 2,
        operatorName: AutomationTimeOperator.IsNot
      },
    ],
    thirdColumn: {
      options: [
        {
          operatorId: 1,
          operatorName: AutomationTimeOperator.AnyDate
        },
        {
          operatorId: 2,
          operatorName: AutomationTimeOperator.LessThan
        },
        {
          operatorId: 3,
          operatorName: AutomationTimeOperator.MoreThan
        },
      ],
      contactStatuses: [],
      operatorId: 1,
      operatorName: AutomationTimeOperator.AnyDate,
      ids: [],
      names: "",
      months: 0,
      weeks: 0,
      days: 0,
      hours: 1,
      hideTimeCondition: true,
    }
  },
  {
    ifElseFirstColumnId: 3,
    ifElseFirstColumnName: "Notes",
    operators: [
      {
        operatorId: 1,
        operatorName: AutomationTimeOperator.Has
      },
      {
        operatorId: 2,
        operatorName: AutomationTimeOperator.HasNot
      },
    ],
    thirdColumn: {
      noteTypes: [],
      displayNoteTypes: "",
      options: []
    }
  },
  {
    ifElseFirstColumnId: AutomationIfElseFirstColumnTypeClient.DateCreated,
    ifElseFirstColumnName: "Date created",
    operators: [
      {
        operatorId: 1,
        operatorName: AutomationTimeOperator.LessThan
      },
      {
        operatorId: 2,
        operatorName: AutomationTimeOperator.MoreThan
      },
    ],
    thirdColumn: {
      months: 0,
      weeks: 0,
      days: 0,
      hours: 0
    }
  },
  {
    ifElseFirstColumnId: AutomationIfElseFirstColumnTypeClient.DateUpdated,
    ifElseFirstColumnName: "Date updated",
    operators: [
      {
        operatorId: 1,
        operatorName: AutomationTimeOperator.LessThan
      },
      {
        operatorId: 2,
        operatorName: AutomationTimeOperator.MoreThan
      },
    ],
    thirdColumn: {
      months: 0,
      weeks: 0,
      days: 0,
      hours: 0
    }
  },
  {
    ifElseFirstColumnId: AutomationIfElseFirstColumnTypeClient.PlacementStatus,
    ifElseFirstColumnName: "Placement status",
    operators: [
      {
        operatorId: 1,
        operatorName: AutomationTimeOperator.Is
      },
      {
        operatorId: 2,
        operatorName: AutomationTimeOperator.IsNot
      },
    ],
    thirdColumn: {
      options: [],
      ids: [],
      names: ""
    }
  },
  {
    ifElseFirstColumnId: AutomationIfElseFirstColumnTypeClient.SurveyScore,
    ifElseFirstColumnName: "Survey score",
    operators: [],
    thirdColumn: {
      options: [
        {
          operatorId: 1,
          operatorName: AutomationTimeOperator.Equal
        },
        {
          operatorId: 2,
          operatorName: AutomationTimeOperator.LessThan
        },
        {
          operatorId: 3,
          operatorName: AutomationTimeOperator.MoreThan
        }
      ],
      surveyList: [],
      ids: [],
      score: 0,
      isActivityAllSelected: false
    }
  }
];

export const AutomationIfElseFirstColumn = [
  {
    ifElseFirstColumnId: 1,
    ifElseFirstColumnName: "Activity",
    operators: [
      {
        operatorId: 1,
        operatorName: AutomationTimeOperator.Is
      },
      {
        operatorId: 2,
        operatorName: AutomationTimeOperator.IsNot
      },
    ],
    thirdColumn: {
      activities: [],
      names: "",
      activityOptions: AutomationRoiActivityList.filter((value, index, arr) => {
        //#5281 only show Email is Invalid (23) and Text is Invalid (24) for Trigger
        return value.id !== 15 && value.id !== 16 && value.id !== 23 && value.id !== 24;
      }),
      subActivities: [],
      subActivityNames: ""
    }
  },
  {
    ifElseFirstColumnId: 3,
    ifElseFirstColumnName: "Status",
    operators: [
      {
        operatorId: 1,
        operatorName: AutomationTimeOperator.Is
      },
      {
        operatorId: 2,
        operatorName: AutomationTimeOperator.IsNot
      },
    ],
    thirdColumn: {
      options: [
        {
          operatorId: 1,
          operatorName: AutomationTimeOperator.AnyDate
        },
        {
          operatorId: 2,
          operatorName: AutomationTimeOperator.LessThan
        },
        {
          operatorId: 3,
          operatorName: AutomationTimeOperator.MoreThan
        },
      ],
      ids: [],
      names: "",
      operatorId: 1,
      operatorName: AutomationTimeOperator.AnyDate,
    }
  },
  {
    ifElseFirstColumnId: AutomationIfElseFirstColumnType.ApplicationStatus,
    ifElseFirstColumnName: "Application Status",
    operators: [
      {
        operatorId: 1,
        operatorName: AutomationTimeOperator.Is
      },
      {
        operatorId: 2,
        operatorName: AutomationTimeOperator.IsNot
      }
    ],
    thirdColumn: {
      options: [
        {
          operatorId: 1,
          operatorName: AutomationTimeOperator.AnyDate
        },
        {
          operatorId: 2,
          operatorName: AutomationTimeOperator.LessThan
        },
        {
          operatorId: 3,
          operatorName: AutomationTimeOperator.MoreThan
        }
      ],
      months: 0,
      weeks: 0,
      days: 0,
      hours: 0
    }
  },
  {
    ifElseFirstColumnId: 2,
    ifElseFirstColumnName: "Notes",
    operators: [
      {
        operatorId: 1,
        operatorName: AutomationTimeOperator.Has
      },
      {
        operatorId: 2,
        operatorName: AutomationTimeOperator.HasNot
      },
    ],
    thirdColumn: {
      noteTypes: [],
      displayNoteTypes: "",
      options: []
    }
  },
  {
    ifElseFirstColumnId: AutomationIfElseFirstColumnType.DateCreated,
    ifElseFirstColumnName: "Date created",
    operators: [
      {
        operatorId: 1,
        operatorName: AutomationTimeOperator.LessThan
      },
      {
        operatorId: 2,
        operatorName: AutomationTimeOperator.MoreThan
      },
    ],
    thirdColumn: {
      months: 0,
      weeks: 0,
      days: 0,
      hours: 0
    }
  },
  {
    ifElseFirstColumnId: AutomationIfElseFirstColumnType.DateUpdated,
    ifElseFirstColumnName: "Date updated",
    operators: [
      {
        operatorId: 1,
        operatorName: AutomationTimeOperator.LessThan
      },
      {
        operatorId: 2,
        operatorName: AutomationTimeOperator.MoreThan
      },
    ],
    thirdColumn: {
      months: 0,
      weeks: 0,
      days: 0,
      hours: 0
    }
  },
  {
    ifElseFirstColumnId: 5,
    ifElseFirstColumnName: "Birthday",
    operators: [
      {
        operatorId: 1,
        operatorName: AutomationTimeOperator.Before
      },
      {
        operatorId: 2,
        operatorName: AutomationTimeOperator.On
      },
      {
        operatorId: 3,
        operatorName: AutomationTimeOperator.After
      },
    ],
    thirdColumn: {
      months: 0,
      weeks: 0,
      days: 0,
      hours: 0
    }
  },
  {
    ifElseFirstColumnId: 4,
    ifElseFirstColumnName: "Net promoter score",
    operators: [
      {
        operatorId: 1,
        operatorName: AutomationTimeOperator.Equal
      },
      {
        operatorId: 2,
        operatorName: AutomationTimeOperator.LessThan
      },
      {
        operatorId: 3,
        operatorName: AutomationTimeOperator.MoreThan
      },
    ],
    thirdColumn: {
      score: null,
    }
  },
  {
    ifElseFirstColumnId: AutomationIfElseFirstColumnType.PlacementStatus,
    ifElseFirstColumnName: "Placement status",
    operators: [
      {
        operatorId: 1,
        operatorName: AutomationTimeOperator.Is
      },
      {
        operatorId: 2,
        operatorName: AutomationTimeOperator.IsNot
      },
    ],
    thirdColumn: {
      options: [],
      ids: [],
      names: ""
    }
  },
  {
    ifElseFirstColumnId: AutomationIfElseFirstColumnType.SurveyScore,
    ifElseFirstColumnName: "Survey score",
    operators: [],
    thirdColumn: {
      options: [
        {
          operatorId: 1,
          operatorName: AutomationTimeOperator.Equal
        },
        {
          operatorId: 2,
          operatorName: AutomationTimeOperator.LessThan
        },
        {
          operatorId: 3,
          operatorName: AutomationTimeOperator.MoreThan
        }
      ],
      surveyList: [],
      ids: [],
      score: 0,
      isActivityAllSelected: false
    }
  }
];

export const UpdateRecordList = {
  Postcode: 1,
  CurrentWorkType: 2,
  Country: 3,
  Status: 4,
  AddNote: 5,
  ApplicationStatus: 8,
}

export const JobAutomationUpdateRecordFirstColumn = [
  {
    id: UpdateRecordList.Status,
    name: "Status",
  },
  {
    id: UpdateRecordList.AddNote,
    name: "Add note",
  },
  {
    id: UpdateRecordList.ApplicationStatus,
    name: "Application status",
  },
];
export const AutomationUpdateRecordFirstColumn = [
  {
    id: UpdateRecordList.Status,
    name: "Status",
  },
  {
    id: UpdateRecordList.AddNote,
    name: "Add note",
  }
];


export const AutomationClientUpdateRecordFirstColumn = [
  {
    id: UpdateRecordList.Status,
    name: "Status",
  },
  {
    id: UpdateRecordList.AddNote,
    name: "Note",
  },
];

export const ListDaysShort = [
  {
    day: "Mon",
    value: "1"
  },
  {
    day: "Tue",
    value: "2"
  },
  {
    day: "Wed",
    value: "3"
  },
  {
    day: "Thu",
    value: "4"
  },
  {
    day: "Fri",
    value: "5"
  },
  {
    day: "Sat",
    value: "6"
  },
  {
    day: "Sun",
    value: "0"
  }
];

export const AutomationSpecificDays = {
  Monday: "1", Tuesday: "2", Wednesday: "3", Thursday: "4", Friday: "5", Saturday: "6", Sunday: "0"
};

export const AutomationOperator = [
  {
    id: 1,
    name: "AND"
  },
  {
    id: 2,
    name: "OR"
  },
];

export const AutomationStateType = {
  Active: 1,
  Inactive: 2,
  Ended: 3,
  Draft: 4,
  Deleted: 5,
  Underway: 6
};

export const SurveyStateType = {
  Active: 1,
  Inactive: 2
};

export const SurveyFormFace = {
  Autoscroll: "autoscroll",
  Chat: "chat",
  Classic: "classic"
};

export const OptionSalaryRate = [
  {
    value: "Hour",
    text: "Hourly Rate"
  },
  {
    value: "Day",
    text: "Daily Rate"
  },
  {
    value: "Week",
    text: "Weekly Rate"
  },
  {
    value: "Month",
    text: "Monthly Rate"
  },
  {
    value: "Year",
    text: "Yearly Rate"
  },
];

export const AtsSyncVendor = {
  JobAdder: 1
}

export const LimitMaxLength = {
  postalCode: 12,
  salary: 8
}

export const RangeNps = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10
]

export const AudienceSource = {
  RoiAi: 1,
  JobAdder: 2
}

export const EmailReportType = {
  Processed: { typeId: 3, label: "Email Sent" },
  Bounced: { typeId: 4, label: "Email Bounced" },
  Opened: { typeId: 5, label: "Email Opened" },
  Delivered: { typeId: 6, label: "Email Delivered" },
  Unsubscribed: { typeId: 15, label: "Email Unsubscribed" },
  LinksClicked: { typeId: 17, label: "Link Clicked" },
  Invalid: { typeId: 23, label: "Invalid" },
  UnsubscribedList: { typeId: 27, label: "Previously Unsubscribed"},
  Links: { typeId: 99, label: "Links" }
}

export const EmailReportCategory = {
  Candidate: 1,
  Client: 2
}

export const TextReportType = {
  Sent: { typeId: 7, label: "Text Sent" },
  Delivered: { typeId: 8, label: "Text Delivered" },
  Unsubscribed: { typeId: 16, label: "Text Unsubscribed" },
  LinksClicked: { typeId: 26, label: "Link Clicked" },
  Links: { typeId: 99, label: "Links" },
  Invalid: { typeId: 24, label: "Invalid" }
}

export const EmailReportChartBarIndex = {
  // these are the index of the chart bar (open rate [0], click rate[1], counce rate[2], unsubscribe rate[3]),,
  Opened: 0,
  LinksClicked: 1,
  Bounced: 2,
  Unsubscribed: 3,
}


export const SurveyReportType = {
  Opened: { typeId: 5, label: "Survey Opened" },
  Completion: { typeId: 99, label: "Survey Completion" } // typeID need ask Backend
}

export const SurveyReportChartBarIndex = {
  // these are the index of the chart bar (open rate [0], Completion rate[1]),,
  Opened: 0,
  Completion: 1,
}

export const TextReportChartBarIndex = {
  // these are the index of the chart bar (open rate [0], click rate[1], counce rate[2], unsubscribe rate[3]),,
  Delivered: 0,
  LinksClicked: 1,
  Unsubscribed: 2,
  Sent: 3
}

export const AutomationType = {
  Candidate: 1,
  Client: 2,
}

export const CustomFieldsType = {
  Candidate: "Candidate",
  Client: "Contact",
}

export const AutomationTypeText = {
  Candidate: "Candidate",
  Contact: "Contact",
}

export const InvalidStatusText = {
  Subscribe: 'Subscribe',
  Unsubscribe: 'Unsubscribe'
}

export const SurveyCustomFieldType = {
  List: "List",
  Text: "Text"
}

export const OwnerTypeCandidate = [
  { label: "Recruiter", value: 1 },
  { label: "Job owner", value: 2 },
  { label: "Admin user", value: 3 },
  { label: "No user", value: 4 },
  { label: "Placement recruiter", value: 5 }
]

export const OwnerTypeClient = [
  { label: "Contact owner", value: 1 },
  { label: "Job owner", value: 2 },
  { label: "Admin user", value: 3 },
  { label: "No user", value: 4 },
  { label: "Placement recruiter", value: 5 }
]

export const UpdateRecordType = [
  { label: "Recruiter", value: 1 },
  { label: "Job owner", value: 2 },
  { label: "Admin user", value: 3 },
  { label: "No user", value: 4 },
  { label: "Placement recruiter", value: 5 }
]

export const UpdateRecordTypeClient = [
  { label: "Contact owner", value: 1 },
  { label: "Job owner", value: 2 },
  { label: "Admin user", value: 3 },
  { label: "No user", value: 4 },
  { label: "Placement recruiter", value: 5 }
]

export const UpdateRecordOwnerType = {
  Recruiter: 1,
  JobOwner: 2,
  AdminUser: 3,
  NoUser: 4,
  PlacementRecruiter: 5
}

export const UpdateRecordNoteType = {
  CandidateNote: 0,
  PlacementNote: 1,
  ApplicationNote: 2,
  JobNote: 3
}

export const OwnerAssignmentOptions = {
  RecentOwner: '1',
  Recruiter: '2'
}

export const EmailCategory = {
  Existing: 1,
  New: 2
};

export const EmailSource = {
  Scratch: 1,
  CopyExisting: 2,
  Template: 3,
  ViewEmail: 4,
};

export const EmailCategories = [
  {
    id: 1,
    name: "Use an existing email"
  },
  {
    id: 2,
    name: "Create new email"
  },
];

export const EmailSources = [
  {
    id: 1,
    name: "Start from scratch"
  },
  {
    id: 2,
    name: "Copy an existing email"
  },
  {
    id: 3,
    name: "Use a template"
  },
];

export const AtsFieldCategoryType = [
  {
    id: 0,
    name: "Candidate",
    displayName: "Candidate tags"
  },
  {
    id: 1,
    name: "CandidateRecruiter",
    displayName: "Candidate recruiter tags"
  },
  {
    id: 2,
    name: "Contact",
    displayName: "Contact tags"
  },
  {
    id: 3,
    name: "ContactOwner",
    displayName: "Contact owner tags"
  },
  {
    id: 4,
    name: "Placement",
    displayName: "Placement tags"
  },
  {
    id: 5,
    name: "Job",
    displayName: "Job tags"
  },
  {
    id: 6,
    name: "JobOwner",
    displayName: "Job owner tags"
  },
  {
    id: 7,
    name: "JobAd",
    displayName: "Job Ad tags"
  },
  {
    id: 8,
    name: "Token",
    displayName: "Token tags"
  },
  {
    id: 9,
    name: "Interview",
    displayName: "Interview tags"
  },
  {
    id: 10,
    name: "Other",
    displayName: "Other tags"
  },
  {
    id: 11,
    name: "Obselete",
    displayName: "Obselete tags"
  },
];

export const CandidateMergeTagsForContacts = [
  {
    "categoryType": "Candidate tags",
    "atsFields": [
      {
        "id": 2,
        "displayName": "Candidate First Name",
        "tokenVariable": "candidate_first_name",
        "atsFieldName": "candidateFirstName",
        "dataType": "string",
        "length": "50",
        "isShow": true,
        "isMandatory": true,
        "contactType": 1,
        "categoryType": 0
      },
      {
        "id": 3,
        "displayName": "Candidate Last Name",
        "tokenVariable": "candidate_last_name",
        "atsFieldName": "candidateLastName",
        "dataType": "string",
        "length": "50",
        "isShow": true,
        "isMandatory": true,
        "contactType": 1,
        "categoryType": 0
      }
    ]
  }
];

export const FolderCategory = {
  Add: 1,
  Remove: 2
};

export const FolderCategories = [
  {
    id: 1,
    name: "Add"
  },
  {
    id: 2,
    name: "Remove"
  }
];

export const UpdateFolderDestination = {
  CandidateContact: 1,
  Placement: 2,
  Application: 3
}