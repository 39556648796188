import { ApiService, ApiUrl, Enum } from "helpers";

export const emailReportApi = {
    getEmailReports(params = {}) {
        const queryParams = {
            page: params.page || 1,
            pageSize: params.pageSize || 10,
            searchTerm: params.searchTerm || "",
            sortBy: params.sortBy || "LastSendDate",
            sortDirection: params.sortDirection || "desc",
            filters: params.filters || {}
        };

        console.log('Making API request to:', ApiUrl.getAllEmailReports);
        console.log('With params:', queryParams);

        return ApiService.post(ApiUrl.getAllEmailReports, queryParams);
    },

    getEmailReportServerSide(request) {
        const body = {
            param: JSON.stringify(request)
        };
        return ApiService.post(`${ApiUrl.getEmailReports}`, body);
    },

    getEmailClientReportServerSide(request) {
        const body = {
            param: JSON.stringify(request)
        };
        return ApiService.post(`${ApiUrl.getEmailClientReports}`, body);
    },

    getEmailReportDetails(contactType, token) {
        const apiUrlEmailReport = contactType === Enum.ContactType.Candidate ? ApiUrl.getEmailReportSelectedDetails : ApiUrl.getEmailReportClientSelectedDetails;
        return ApiService.getDetail(apiUrlEmailReport, token);
    },

    getEmailReportDetailsUnsubscribed(contactType, token, params = {}) {
        const queryParams = {
            reportToken: token,
            page: params.page || 1,
            pageSize: params.pageSize || 10,
            sortBy: params.sortBy || "activityDate",
            sortDirection: params.sortDirection || "desc",
            searchTerm: params.searchTerm || "",
            type: params.type || Enum.EmailReportType.Unsubscribed.typeId
        };

        const apiUrlEmailReport = contactType === Enum.ContactType.Candidate ? ApiUrl.getEmailReports : ApiUrl.getEmailClientReports;
        return ApiService.getDetail(apiUrlEmailReport, `${queryParams.type}?${new URLSearchParams(queryParams).toString()}`);
    },

    getEmailReportDetailsDelivered(contactType, token, params = {}) {
        const queryParams = {
            reportToken: token,
            page: params.page || 1,
            pageSize: params.pageSize || 10,
            sortBy: params.sortBy || "activityDate",
            sortDirection: params.sortDirection || "desc",
            searchTerm: params.searchTerm || "",
            type: params.type || Enum.EmailReportType.Delivered.typeId
        };

        const apiUrlEmailReport = contactType === Enum.ContactType.Candidate ? ApiUrl.getEmailReports : ApiUrl.getEmailClientReports;
        return ApiService.getDetail(apiUrlEmailReport, `${queryParams.type}?${new URLSearchParams(queryParams).toString()}`);
    },

    getEmailReportDetailsOpened(contactType, token, params = {}) {
        const queryParams = {
            reportToken: token,
            page: params.page || 1,
            pageSize: params.pageSize || 10,
            sortBy: params.sortBy || "activityDate",
            sortDirection: params.sortDirection || "desc",
            searchTerm: params.searchTerm || "",
            type: params.type || Enum.EmailReportType.Opened.typeId
        };

        const apiUrlEmailReport = contactType === Enum.ContactType.Candidate ? ApiUrl.getEmailReports : ApiUrl.getEmailClientReports;
        return ApiService.getDetail(apiUrlEmailReport, `${queryParams.type}?${new URLSearchParams(queryParams).toString()}`);
    },

    getEmailReportDetailsLinksClicked(contactType, token, params = {}) {
        const data = {
            reportToken: token,
            page: params.page || 1,
            pageSize: params.pageSize || 10,
            sortBy: params.sortBy || "activityDate",
            sortDirection: params.sortDirection || "desc",
            searchTerm: params.searchTerm || "",
            type: params.type || Enum.EmailReportType.LinksClicked.typeId
        };

        const apiUrlEmailReport = contactType === Enum.ContactType.Candidate ? ApiUrl.getEmailReportClicked : ApiUrl.getEmailReportClientClicked;
        return ApiService.post(apiUrlEmailReport, data);
    },

    getEmailReportDetailsLinks(contactType, token, params = {}) {
        const queryParams = {
            page: params.page || 1,
            pageSize: params.pageSize || 10,
            sortBy: params.sortBy || "total",
            sortDirection: params.sortDirection || "desc",
            searchTerm: params.searchTerm || ""
        };
        const apiUrlEmailReport = contactType === Enum.ContactType.Candidate ? ApiUrl.getEmailReportLink : ApiUrl.getEmailReportClientLink;
        return ApiService.getDetail(apiUrlEmailReport, token + "?" + new URLSearchParams(queryParams).toString());
    },

    getEmailReportLinkPageDetails(contactType, token, linkName, linkUrl) {
        const data = {
            reportToken: token,
            linkName: linkName,
            linkUrl: linkUrl,
        };
        const apiUrlEmailReport = contactType === Enum.ContactType.Candidate ? ApiUrl.getEmailReportLinkDetails : ApiUrl.getEmailReportClientLinkDetails;
        return ApiService.post(apiUrlEmailReport, data);
    },

    getEmailReportDetailsSent(contactType, token, params = {}) {
        const queryParams = {
            reportToken: token,
            page: params.page || 1,
            pageSize: params.pageSize || 10,
            sortBy: params.sortBy || "activityDate",
            sortDirection: params.sortDirection || "desc",
            searchTerm: params.searchTerm || "",
            type: params.type || Enum.EmailReportType.Processed.typeId
        };

        const apiUrlEmailReport = contactType === Enum.ContactType.Candidate ? ApiUrl.getEmailReports : ApiUrl.getEmailClientReports;
        return ApiService.getDetail(apiUrlEmailReport, `${queryParams.type}?${new URLSearchParams(queryParams).toString()}`);
    },

    getEmailReportDetailsInvalid(contactType, token, params = {}) {
        const queryParams = {
            reportToken: token,
            page: params.page || 1,
            pageSize: params.pageSize || 10,
            sortBy: params.sortBy || "activityDate",
            sortDirection: params.sortDirection || "desc",
            searchTerm: params.searchTerm || "",
            type: params.type || Enum.EmailReportType.Invalid.typeId
        };

        const apiUrlEmailReport = contactType === Enum.ContactType.Candidate ? ApiUrl.getEmailReports : ApiUrl.getEmailClientReports;
        return ApiService.getDetail(apiUrlEmailReport, `${queryParams.type}?${new URLSearchParams(queryParams).toString()}`);
    },

    getEmailReportDetailsUnsubscribeList(contactType, token, params = {}) {
        const queryParams = {
            reportToken: token,
            page: params.page || 1,
            pageSize: params.pageSize || 10,
            sortBy: params.sortBy || "activityDate",
            sortDirection: params.sortDirection || "desc",
            searchTerm: params.searchTerm || "",
            type: params.type || Enum.EmailReportType.UnsubscribedList.typeId
        };

        const apiUrlEmailReport = contactType === Enum.ContactType.Candidate ? ApiUrl.getEmailReports : ApiUrl.getEmailClientReports;
        return ApiService.getDetail(apiUrlEmailReport, `${queryParams.type}?${new URLSearchParams(queryParams).toString()}`);
    },

    getEmailReportDetailsBounced(contactType, token, params = {}) {
        const queryParams = {
            reportToken: token,
            page: params.page || 1,
            pageSize: params.pageSize || 10,
            sortBy: params.sortBy || "activityDate",
            sortDirection: params.sortDirection || "desc",
            searchTerm: params.searchTerm || "",
            type: params.type || Enum.EmailReportType.Bounced.typeId
        };

        const apiUrlEmailReport = contactType === Enum.ContactType.Candidate ? ApiUrl.getEmailReports : ApiUrl.getEmailClientReports;
        return ApiService.getDetail(apiUrlEmailReport, `${queryParams.type}?${new URLSearchParams(queryParams).toString()}`);
    }
};
