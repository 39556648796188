import { Enum } from "helpers";
import { observable } from "mobx";
import { emailReportApi } from "./api";
import { createEmailReportActions } from "./actions";
import { createMutations } from "./mutations";
import { initialState, initialGridColumns } from "./state";

const createEmailReportStore = () => {
  const store = observable({
    ...initialState,
    gridColumn: initialGridColumns,

    // Computed values
    getDataId() {
      return this.dataId;
    },

    getDataIdClient() {
      return this.dataIdClient;
    },

    countDataSet(data) {
      return [
        data?.openedPercentage.toFixed(1),
        data?.linkClickedPercentage.toFixed(1),
        data?.bouncedPercentage.toFixed(1),
        data?.unsubscribePercentage.toFixed(1)
      ];
    },

    // Reset methods
    resetReportType() {
      this.selectedDetailsType = { typeId: 0, label: null };
      this.selectedDetailsToken = null;
    },

    resetEmailReportStore() {
      Object.assign(this, initialState);
    },

    // API methods
    getEmailReports(params = {}) {
      return emailReportApi
        .getEmailReports(params)
        .then(createEmailReportActions(this).handleGetEmailReports)
        .catch(createEmailReportActions(this).handleError);
    },

    getEmailReportServerSide(request) {
      return emailReportApi
        .getEmailReportServerSide(request)
        .then(createEmailReportActions(this).handleGetEmailReportServerSide)
        .catch(createEmailReportActions(this).handleError);
    },

    getEmailClientReportServerSide(request) {
      return emailReportApi
        .getEmailClientReportServerSide(request)
        .then(
          createEmailReportActions(this).handleGetEmailClientReportServerSide
        )
        .catch(createEmailReportActions(this).handleError);
    },

    getEmailReportDetails(contactType, token) {
      return emailReportApi
        .getEmailReportDetails(contactType, token)
        .then(response =>
          createEmailReportActions(this).handleGetEmailReportDetails(
            response,
            contactType
          )
        )
        .catch(createEmailReportActions(this).handleError);
    },

    getEmailReportDetailsUnsubscribed(contactType, token, params = {}) {
      return emailReportApi.getEmailReportDetailsUnsubscribed(
        contactType,
        token,
        {
          ...params,
          type:
            this.selectedDetailsType.typeId ||
            Enum.EmailReportType.Unsubscribed.typeId
        }
      );
    },

    getEmailReportDetailsDelivered(contactType, token, params = {}) {
      return emailReportApi.getEmailReportDetailsDelivered(contactType, token, {
        ...params,
        type:
          this.selectedDetailsType.typeId ||
          Enum.EmailReportType.Delivered.typeId
      });
    },

    getEmailReportDetailsOpened(contactType, token, params = {}) {
      return emailReportApi.getEmailReportDetailsOpened(contactType, token, {
        ...params,
        type:
          this.selectedDetailsType.typeId || Enum.EmailReportType.Opened.typeId
      });
    },

    getEmailReportDetailsLinksClicked(contactType, token, params = {}) {
      return emailReportApi.getEmailReportDetailsLinksClicked(
        contactType,
        token,
        {
          ...params,
          type:
            this.selectedDetailsType.typeId ||
            Enum.EmailReportType.LinksClicked.typeId
        }
      );
    },

    getEmailReportDetailsLinks(contactType, token, params = {}) {
      return emailReportApi.getEmailReportDetailsLinks(
        contactType,
        token,
        params
      );
    },

    getEmailReportLinkPageDetails(contactType, token, linkName, linkUrl) {
      // Cache these values in case they are cleared during API processing
      const storedLinkName = linkName;
      const storedLinkUrl = linkUrl;
      
      // Ensure these values are set in the store
      if (linkName) this.setEmailReportLinkTitle(storedLinkName);
      if (linkUrl) this.setEmailReportLinkOriginalUrl(storedLinkUrl);
      
      return emailReportApi
        .getEmailReportLinkPageDetails(contactType, token, storedLinkName, storedLinkUrl)
        .then((response) => {
          // Re-set these values in case they were cleared during API processing
          if (storedLinkName) this.setEmailReportLinkTitle(storedLinkName);
          if (storedLinkUrl) this.setEmailReportLinkOriginalUrl(storedLinkUrl);
          return createEmailReportActions(this).handleGetEmailReportLinkPageDetails(response);
        })
        .catch((error) => {
          // Even on error, ensure our values are preserved
          if (storedLinkName) this.setEmailReportLinkTitle(storedLinkName);
          if (storedLinkUrl) this.setEmailReportLinkOriginalUrl(storedLinkUrl);
          return createEmailReportActions(this).handleError(error);
        });
    },

    getEmailReportDetailsSent(contactType, token, params = {}) {
      return emailReportApi.getEmailReportDetailsSent(contactType, token, {
        ...params,
        type:
          this.selectedDetailsType.typeId ||
          Enum.EmailReportType.Processed.typeId
      });
    },

    getEmailReportDetailsInvalid(contactType, token, params = {}) {
      return emailReportApi.getEmailReportDetailsInvalid(contactType, token, {
        ...params,
        type:
          this.selectedDetailsType.typeId || Enum.EmailReportType.Invalid.typeId
      });
    },

    getEmailReportDetailsUnsubscribeList(contactType, token, params = {}) {
      return emailReportApi.getEmailReportDetailsUnsubscribeList(
        contactType,
        token,
        {
          ...params,
          type:
            this.selectedDetailsType.typeId ||
            Enum.EmailReportType.UnsubscribedList.typeId
        }
      );
    },

    getEmailReportDetailsBounced(contactType, token, params = {}) {
      return emailReportApi.getEmailReportDetailsBounced(contactType, token, {
        ...params,
        type:
          this.selectedDetailsType.typeId || Enum.EmailReportType.Bounced.typeId
      });
    }
  });

  // Add mutations to the store
  Object.assign(store, createMutations(store));

  return store;
};

export default createEmailReportStore();
